import React from 'react'
import { Segment, Label, Button, Modal, Table, Header, Dimmer, Loader, Grid } from 'semantic-ui-react';
import _ from 'lodash'
import axios from 'axios'
import AddForm from './AddForm'
import { BlueMoon } from '../BlueMoon';
import CollectorCard from './CollectorCard'
import {withRouter} from 'react-router-dom'

class CollectionMetaDriller extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: '',
            modalOpen: false,
            treeData: [], nameToIcon: {
                'social': 'fa fa-globe',
                'facebook': 'fa fa-facebook',
                'twitter': 'fa fa-twitter',
                'instagram': 'fa fa-instagram',
                'highlights': 'fa fa-star',
                'likes': 'fa fa-thumbs-up',
                'comments': 'fa fa-comment',
                'docCount': 'fa fa-file',
                'meScore': 'fa fa-money',
                'linear': 'fa fa-tv',
                'digital': 'fa fa-video-camera',
                'mediaCount': 'fa fa-calculator',
                'shares': 'fa fa-share',
                'views': 'fa fa-eye',
                'youtube': 'fa fa-youtube'
            }
        };
    }

    metaSquareCompute = (data) => {

        return (data.reduce((prev, curr) => {
            var currentKeys = _.keys(curr);
            return currentKeys.reduce((pre, cur) => {
                if (cur in pre)

                    pre[cur] = pre[cur] + curr[cur];

                return pre;
            }, prev)
        }, {
            comments: 0,
            likes: 0,
            docCount: 0,
            meScore: 0
        }));
    }


    shapeTreeData = (data) => {
        this.setState({
            treeData: data.reduce((prev, curr) => {
                var channelName = curr.channel;
                var channelType = curr.channelType;
                var website = curr.website;
                var tempObj = {};
                var metaInfo = _.pick(curr, ["likes", "comments", "meScore", "docCount"])
                tempObj[channelName] = {};
                tempObj[channelName][channelType] = {};
                if ('website' in curr) {
                    tempObj[channelName][channelType][website] = {};
                    tempObj[channelName][channelType][website] = metaInfo;
                } else
                    tempObj[channelName][channelType] = metaInfo
                return _.merge(prev, tempObj);

            }, {})
        }, () => {
            this.setState({ metaSquare: (this.metaSquareCompute(data)) });
        }
        );
    }

    formatParams = (filter) => {
    console.log(filter)
       return _.keys(filter).reduce((prev, curr) => {
           if(curr === "platform")
           return Object.assign(prev, {[curr] :`${filter[curr].join("<<!!>>")}`.toLowerCase() })
           else if(curr === "eventDates")
           return Object.assign(prev, {[curr] :`${filter[curr].join("<<!!>>")}` })
           else
           return Object.assign(prev, {[curr] :`${filter[curr].join("<<!!>>")}`})
        }, {})
    }

    fetchMetaData = (filter) => {
        console.log(this.formatParams(filter))
        axios.get(`${BlueMoon.ip}filterGetMeta/`, {
            params: this.formatParams(filter)
        }).then((Response) => {
            this.shapeTreeData(Response.data);
        })
    }

    componentWillReceiveProps = (nextProps, prevProps) => {
        if (!_.isEqual(nextProps.filters, prevProps.filters))
            this.setState({ filters: nextProps.filters, treeData: {} }, () => {
                this.fetchMetaData(nextProps.filters);
            });
    }



    componentDidMount = () => {
        console.log("component did mount fired")
        this.setState({ filters: this.props.filters }, () => this.fetchMetaData(this.props.filters));
    }


    fireMetaSquare = () => {
        if (!_.isEmpty(this.state.metaSquare))

            return (
                <div style={{ backgroundColor: 'black', color: 'white' }}>
                    <Header as='h2' attached='top' color={'white'} style={{ backgroundColor: 'teal', border: 'none', fontFamily: 'sans-serif', fontWeight: '900', fontSize: '20px', color: 'white' }}>
                        Overall Information
    </Header>

                    <Table celled style={{ backgroundColor: 'black', color: 'white', margin: '0em' }}>
                        <Table.Body>
                            <Table.Row>
                                {
                                    _.keys(this.state.metaSquare).map((key) => {
                                        return (
                                            <Table.Cell>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <i class={this.state.nameToIcon[key]} style={{ fontSize: '30px', color: 'white', paddingTop: '0em' }} /> </Grid.Column>
                                                        <Grid.Column verticalAlign={'middle'}>
                                                            <b style={{ color: 'white', marginLeft: '1rem' }}>{this.state.metaSquare[key]}</b>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Table.Cell>
                                        )
                                    })
                                }
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            );
        return null;
    }

    treeTraversal = (data) => {
        var list = [];
        var childrenFlag = 1;
        var keys = _.keys(data);
        for (var i = 0; i < keys.length; i++) {
            var key = keys[i]
            if (_.isObject(data[key])) {
                childrenFlag = 0;
                var recursiveInformation = this.treeTraversal(data[keys[i]])
                var hasChildren = recursiveInformation.hasChildren;
                var recursiveTreeSofar = recursiveInformation.recursiveTreeSofar;
                list.push(<Segment.Group style={{ backgroundColor: (!hasChildren) ? 'black' : 'teal', borderWidth: '1px', fontFamily: 'sans-serif', fontWeight: '900', fontSize: '15px' }}>{(key in this.state.nameToIcon) ? <i class={this.state.nameToIcon[key]} style={{ fontSize: '30px' }} /> : `${_.capitalize(key)}`}
                    <Segment.Group stacked horizontal={(hasChildren) ? true : false} style={{ backgroundColor: (!hasChildren) ? 'black' : 'teal', borderWidth: '1px' }}>
                        {recursiveTreeSofar}
                    </Segment.Group>
                </Segment.Group>
                )
            } else
                list.push(<Table.Cell width={1} inverted style={{ backgroundColor: 'black', padding: '1em' }}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column style={{ width: '3rem' }} verticalAlign={'top'}>{(key in this.state.nameToIcon) ? <i class={this.state.nameToIcon[key]} style={{ fontSize: '30px', color: 'white', marginTop: '0.1rem' }} /> : `${_.capitalize(key)}`}</Grid.Column>
                            <Grid.Column style={{ width: '15rem' }}><Label style={{ width: '80%' }} size={'big'} color={'blue'}>{data[key]}</Label></Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Table.Cell>)
        }
        if (childrenFlag == 1) {
            var headerList = [];
            headerList.push(<Table stackable style={{ backgroundColor: 'black' }} ><Table.Body><Table.Row>{list}</Table.Row></Table.Body></Table>)
            return { hasChildren: true, recursiveTreeSofar: headerList }
        }
        else
            return { hasChildren: false, recursiveTreeSofar: list }
    }


    onModelClose = () => {
        this.setState({ modalOpen: false, modelVisible: false}, () => {
            setTimeout(()=>{ this.props.history.push('/analytics')}, 2000);
        })
    }
    render() {
        var tree = this.treeTraversal(this.state.treeData).recursiveTreeSofar
        // console.log(this.state.treeData);
        if (!_.isEmpty(this.state.treeData))
            return (
                <div style={{ marginTop: '2rem' }}>
                        <CollectorCard data = {this.state.treeData}/>

                    <Modal  open={this.state.modalOpen} trigger={<Button  onClick={() => { this.setState({ modalOpen: true }) }} color={'blue'} style={{ marginTop: '1rem', visibility: _.isUndefined(this.state.modelVisible)?"visible": "hidden"}} floated={'right'}>Add Collection</Button>} closeIcon onClose={() => { this.setState({ modalOpen: false }) }}>
                        <Modal.Header style={{ textAlign: 'center' }}>{"Collection Setup"}</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <AddForm modelClose={this.onModelClose} filteredQuery = {this.formatParams(this.state.filters)} notificationService = {this.props.notificationService}/>
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>
                </div>
            )
        return <Dimmer style = {{marginTop: '2rem'}} active = {true}>
        <Loader indeterminate>Fetching Meta Data .....</Loader>
      </Dimmer>

    }

}

export default withRouter(CollectionMetaDriller);