import React from 'react'
import ReactEcharts from 'echarts-for-react';
import {  Loader } from 'semantic-ui-react'
import axios from 'axios'
import {Dimple, BlueMoon, RequestToken} from '../BlueMoon'
import _ from "lodash"
import {setTimer} from '../UserBehaviour/UserBehaviourTimer'
import ErrorMessage from '../Common/ErrorMessage';
import {numberWithCommas} from '../Common/NumberWithComas'

class BarChart3D extends React.Component {
    constructor(props) {
        super(props);
        console.log("barchart method is called");
        this.state = {
            xAxis3D: [], yAxis3D: [], 
            params: this.props.params,
            noOccBar: {}, channelToColor:  {
                "Social": '#4444A5',
                "Digital": "yellow",
                "Highlights": '#FF9027',
                "Linear": '#44A1A5',
            }, series: [], option: [], series: [], barChartData: [], error: false
        }
    }

    initAxis = () => {
        
        this.setState({ xAxis3D: this.uniqueBrandNames(this.state.barChartData), yAxis3D: this.uniqueDates(this.state.barChartData) }, () => {
            let returnParams = this.barChart3D(this.state.barChartData);
            this.setState({ option: returnParams.options, series: returnParams.series, noOccBar: {}, modalOpen: false, selectedBrandDetails: [] })
        });
    }

    fetchBarData = () => {
        axios.get(`${BlueMoon.ip}dashboard/brandOccurence/`, {
            params: this.state.params
        }).then((response) => {
            if(!_.isEmpty(response.data.brandOcc))
            this.setState({barChartData: response.data.brandOcc, error: false}, () => {
                this.initAxis();
            });
            else
            this.setState({
                barChartDate: [], error: true
            })
        }).catch((err)=>{
            RequestToken();
        });;
    }

    componentDidMount = () => {
        setTimer('analysis')
        this.fetchBarData();
    }

    componentWillReceiveProps = (nextProps, currentProps) => {
        setTimer('analysis')
        if (!_.isEqual(nextProps.params, currentProps.params)) {
            this.setState({params: nextProps.params, xAxis3D: [], yAxis3D: [], option: [], series: [], barChart3D:[] }, () => this.fetchBarData());
            return true;
        } return false;
    }

    reduceIt = (prev, curr) => {
        if (!(curr.channelName in prev))
            prev[curr.channelName] = {};
        if (!(curr.date in prev[curr.channelName]))
            prev[curr.channelName][curr.date] = {};
        prev[curr.channelName][curr.date][curr.brandName] = curr.brandOccurence;

        return prev;
    }

    uniqueBrandNames = (data) => {
        var brands = Array.from(new Set(data.map((x) => {
            return x.brandName;
        })));
        return brands.filter((date) => {
            return !_.isUndefined(date);
        });
    }

    uniqueDates = (data) => {
        var dates = Array.from(new Set(data.map((x) => {

            return x.date;
        })));

        return dates.filter((date) => {
            return !_.isUndefined(date);
        });
    }


    fillZerosForNonOccurence = (data) => {
        data = data.reduce(this.reduceIt, {});
        var uniqueBrandNames = this.state.xAxis3D;
        return Object.keys(data).reduce((prev, channelName) => {
            prev[channelName] = Object.keys(data[channelName]).reduce((prev, dateOfOccurence) => {

                var brandsFiltered = uniqueBrandNames.filter((x) => {
                    if (!(x in data[channelName][dateOfOccurence]))
                        return true;
                })
                brandsFiltered.map((neededBrand) => {
                    data[channelName][dateOfOccurence][neededBrand] = 0;
                })
                prev[dateOfOccurence] = data[channelName][dateOfOccurence];
                return prev;
            }, {})
            return prev;
        }, {})
    }

    intersect = (a, b) => {
        var finalSet = new Set();
        for (var i = 0; i < a.length; i++) {
            for (var j = 0; j < b.length; j++) {
                if (_.isEqual(a[i], b[j])) {
                    finalSet.add(b[j]);

                }
            }
        }
        return Array.from(finalSet);
    }

    findIntersection = (noOcc) => {

        return Object.keys(noOcc).reduce((prev, curr) => {
            if (prev.length == 0) {
                return noOcc[curr]
            }
            console.log(noOcc[curr], prev)
            return this.intersect(noOcc[curr], prev);
        }, [])
    }

    elementPresent = (element, iterable) => {
        for (var i = 0; i < iterable.length; i++)
            if (_.isEqual(iterable[i], element))
                return true;
        return false
    }

    removeChannel = (BarData3D, noOccurenceDates) => {

        return _.keys(BarData3D).reduce((prev, curr) => {
            prev[curr] = BarData3D[curr].filter((data) => {
                return !(this.elementPresent(data, noOccurenceDates))
            })
            return prev;
        }, {});
    }

    dataPreparion3DbarPlot = (data) => {
        var BarData3D = []
        var groupedChannelData = this.fillZerosForNonOccurence(data);
        var noOcc = {};
        Object.keys(groupedChannelData).map((channelName) => {
            var tempSeries = [];
            (this.state.yAxis3D).map((date, dateIndex) => {

                (this.state.xAxis3D).map((brandName, brandIndex) => {
                    var flag = 0
                    if (_.isUndefined(groupedChannelData[channelName][date])) {
                        tempSeries.push([brandIndex, dateIndex, 0]);
                        flag = 1;
                    }

                    else if (_.isUndefined(groupedChannelData[channelName][date][brandName])) {
                        tempSeries.push([brandIndex, dateIndex, 0]);
                        flag = 1;
                    }
                    else
                        tempSeries.push([brandIndex, dateIndex, groupedChannelData[channelName][date][brandName]])
                    if (flag == 1) {
                        if (!(channelName in noOcc))
                            noOcc[channelName] = []
                        noOcc[channelName].push([brandIndex, dateIndex, 0])

                    }
                });
            });
            BarData3D[channelName] = tempSeries;

        });
        return BarData3D;
    }




    barChart3D = (data) => {
        var returnParams = {
            options: [],
            series: []
        }
     
        var option = {};
        var brands = this.state.xAxis3D;
        var dates = this.state.yAxis3D;
       
        var data = this.dataPreparion3DbarPlot(data);
        // console.log("3d data generation");
   
        option = {
            backgroundColor: '#161A1C',
            tooltip: {
                trigger: 'item',
                formatter: function(params){
                    return `On: ${dates[params.data[1]]}<br/> Brand: ${brands[params.data[0]]}<br/> Duration: ${numberWithCommas(params.data[2])}s`
                }},
            xAxis3D: {
                name: '',
                type: 'category',
                data: brands,
                textColor: '#313695'
                , axisLabel: {
                    fontFamily: "sans-serif",
                    margin: 14,
                    interval: 4,
                    fontSize: 10,

                    formatter: function (d) {

                        return d;
                    }
                }
            },
            yAxis3D: {
                name: 'duration',
                type: 'category',
                data: dates,
                axisLabel: {
                    fontFamily: "sans-serif",
                    interval: 3,
                    fontSize: 10,
                    formatter: function (d) {

                        return d;
                    }
                }
            },
            zAxis3D: {
                name: 'occurences',
                type: 'value'
            },
            grid3D: {
                top : '0',
                axisLine: {
                    lineStyle: {
                        color: 'white'
                    }

                },
                height: "60%",
                boxWidth: 300,
                boxDepth: 150,
                viewControl: {
                    // projection: 'orthographic'
                },
                light: {
                    main: {
                        intensity: 1.2,
                        shadow: true
                    },
                    ambient: {
                        intensity: 0.3
                    }
                }
            },

            series: Object.keys(data).map((channelName) => {
                return ({
                   
                    itemStyle: {
                        opacity: 0.85
                    },

                    type: 'bar3D',
                    data: data[channelName],
                    stack: 'stack',
                    shadding: 'color',
                   
                    emphasis: {
                        label: {
                            show: false
                        }
                    },

                    color: this.state.channelToColor[_.capitalize(channelName)]
                })
            })
        }
        returnParams.options = option;
        returnParams.series = Object.keys(data);
        return returnParams;

    };

    onChartClick = (obj) => {
        var coords = obj.value;
        console.log(obj);
        var selectedBrandDetails = {};

        this.setState({ selectedBrandDetails: { brandName: this.state.xAxis3D[coords[0]], date: this.state.yAxis3D[coords[1]], Occurence: coords[2], channelName: this.state.series[obj.seriesIndex] }, modalOpen: true });
    }


    handleClose = () => this.setState({ modalOpen: false })


    render() {

        console.log("bar chart data");
        
        if(_.isEmpty(localStorage.getItem('user'))) window.location.replace(`${Dimple}#/${localStorage.getItem('companyLogo')}`);

        if (_.isEmpty(localStorage.getItem('projectIp'))) window.location.replace(`${Dimple}#/${localStorage.getItem('companyLogo')}`)

        if(localStorage.getItem('userRole') !== "admin")  return <ErrorMessage isNormal = {true}/>

        if(this.state.error) return <ErrorMessage/>
        if (_.isEmpty(this.state.xAxis3D) | _.isEmpty(this.state.yAxis3D) | _.isEmpty(this.state.option)) return <Loader active/>
        return (
            <div style={{ height: document.body.scrollHeight+ "px" }}>
                <ReactEcharts
                    style={{ marginTop: '1rem', height: '100%' }}
                    option={this.state.option}
                    notMerge={true}
                    lazyUpdate={true}
                    theme={"dark"}

                />
            </div>
        );



    }
}

export default BarChart3D;