import React from 'react'
import { Segment, Menu, Dropdown } from 'semantic-ui-react'
import { withRouter } from "react-router-dom";
import {BlueMoon} from '../BlueMoon'
import axios from 'axios'
import _ from 'lodash'
import {Balerion} from '../BlueMoon'

class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',

    };
  }

  update = (userName) => {
    this.setState({
      userName: userName
    })
  }


  updateLoggedOutTime = () => {
  }

  updateUserBehaviour = () => {
    
  }

  deleteToken = () =>{
    axios.delete(`${Balerion}deleteToken`, {
      params: {
        authToken: `${localStorage.getItem('token')}`
      }
    }).then((response) => {
      if(response.data){
        localStorage.setItem('token', '');
      }
    })
  }

  render() {
    var logo = localStorage.getItem("logoSrc");
    console.log(logo)
    return (
      <Segment inverted style={{ backgroundColor: 'black', padding: '0px', margin: 0 }}>
        <Menu inverted secondary fixed = {'top'} style = {{backgroundColor: 'black', width: '100%'}} >
          <Menu.Item position={'left'}>
            {<img  src = {( !_.isEmpty(logo) )?logo: null} id = {'logo'}  height={'100%'} width={'100%'} />}
          </Menu.Item>
         
          <Menu.Menu>
            <Dropdown item text={_.capitalize(localStorage.getItem('user'))}>
              <Dropdown.Menu>
              <Dropdown.Item
                  onClick={
                    () => {
                      localStorage.removeItem('projectIp')
                      document.getElementById("logo")
                      this.props.history.push('/home')
                    }}
                >Home</Dropdown.Item>
            

                <Dropdown.Item
                  onClick={
                    () => {
                      this.props.tempFunc();
                      var companyLogo = localStorage.getItem("companyLogo");
                      localStorage.clear();
                      this.deleteToken();
                      localStorage.removeItem('projectIp')
                      this.props.history.push(`/${companyLogo}`)
                    }}
                >Log Out</Dropdown.Item>

              

              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>

      </Segment>
    )
  }
}

export default withRouter(MenuBar);