import React from 'react'
import { Table, Loader, Modal } from 'semantic-ui-react'
import _ from 'lodash'

class Status extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modelOpen: true, editables: this.props.editables }
    }

    modifiedColumns = (oldData, newData) => {
        var modifications = _.keys(oldData).reduce((prev, curr) => {
            return !_.isEqual(oldData[curr], newData[curr]) ? prev + ` ${(curr === "meScore") ? "ROI($)" : _.capitalize(curr)} = ${newData[curr]}` : prev
        }, "");
        return modifications;
    }

    deletedRows = (row) => {
        return `Brand = ${row['brand']}, Location = ${row['location']} ROI($) = ${row['meScore']}`
    }

    componentWillReceiveProps = (nextProps, currentProps) => {
       this.setState({editable: nextProps.editables});
    }

    statusTable = () => {
        return (
            <Table celled striped inverted>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Operation</Table.HeaderCell>
                        <Table.HeaderCell >Modification Request</Table.HeaderCell>
                        <Table.HeaderCell>Updates</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        this.state.editables.map((edit) => {
                            console.log(edit);
                            return <Table.Row>
                                <Table.Cell>{edit.operation}</Table.Cell>
                                <Table.Cell>{(edit.operation === "edit") ? this.modifiedColumns(edit.data[0], edit.data[1]) : this.deletedRows(edit.data[0])}</Table.Cell>
                                <Table.Cell>{
                                    (!edit.status) ? <Loader size='mini' active inline /> : <i class="fa fa-check" style={{ color: 'green' }} aria-hidden="true"> </i>}</Table.Cell>
                            </Table.Row>
                        })
                    }
                </Table.Body>
            </Table>)
    }


    render() {
        
        if (!_.isEmpty(this.props.editables))
            return (
                <Modal open={this.state.modelOpen} centered={true} closeIcon style={{ backgroundColor: '#333' }} onClose={() => {
                    console.log("selected modal close", this.state.editables);
                    var statusCompleted = this.state.editables.reduce((prev, curr) => {
                        if(curr.status)
                            prev = prev + 1;
                        return prev; 
                    }, 0);
                    
                        console.log(statusCompleted);

                    if(statusCompleted === this.state.editables.length){   
                            this.setState({ modelOpen: false }, () => {
                                this.props.refresh();
                            })
                        }
                    
                }}>
                    {this.statusTable()}
                </Modal>
            );
        return null;
    }

}


export default Status;