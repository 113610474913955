import React from 'react'
import { Grid, Icon, Statistic } from 'semantic-ui-react'
import _ from 'lodash'
class ErrorMessage extends React.Component {

    constructor(props) {
        super(props);
        this.state = { simplyUpdate: false }
    }

    componentDidUpdate = () => {
        this.setState({
            simplyUpdate: !this.state.simplyUpdate
        })
    }

    componentDidUpdate = () => {
        document.getElementsByClassName("text value")[0].style.fontSize = "2em !important";
        document.getElementsByClassName("text value")[0].style.minHieght = "0";

    }

    render() {
        return (
            <Grid style={{ marginLeft: '6rem', marginTop: '1rem', backgroundColor: '#D85A5A', width: '24.5%', fontFamily: 'sans-serif', borderRadius: '10px' }}>
                <Grid.Row style={{ height: '20px', }}>
                    <Grid.Column width={11} style={{ fontSize: '1.5em', color: 'black'}}>
                        Error
                    </Grid.Column>
                    <Grid.Column width={3} textAlign={'center'} verticalAlign={'middle'} style={{ padding: '2px',  width: '100%', borderRadius: '10px' }}>
                            <Icon circular name={'exclamation'} size={'large'} style = {{backgroundColor: 'black', color: '#D85A5A'}} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={{ height: '20px', fontFamily: 'sans-serif' }}>
                        <Statistic>
                            <Statistic.Value text style={{ fontSize: '10em !important', minHieght: '0' }}>
                                N/A
                            </Statistic.Value>
                        </Statistic>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={{ fontFamily: 'sans-serif', fontWeight: 'bolder', color: 'black'}}>
                    {_.isUndefined(this.props.isNormal)?"No data available": "You are not authorized to access !! "}
                        </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

}

export default ErrorMessage