import _ from 'lodash'

function checkTypeVisible(channelNames){
    if(!_.isUndefined(channelNames))
    {
        if(!_.isArray(channelNames))
    channelNames = channelNames.split("<<!!>>");
    return channelNames.reduce((prev, curr) => {
        if(curr === "Linear" || curr === "Highlights")
        return prev || false
        return prev || true
    }, false);
}
return true;
}

var shouldTypeVisible = checkTypeVisible

export  {shouldTypeVisible};