import React from 'react'
import { Table, Icon, Grid, Label, Segment, Header } from 'semantic-ui-react';
import _ from 'lodash'
import { channelToColor } from '../Common/ChannelToColor'
import { numericConvertor } from '../Common/NumericConvertor'
import { isMobile } from 'react-device-detect';

class CollectorCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            channelToIcon: {
                "channelType": "star", "likes": "thumbs up", "comments": "comment", "meScore": "dollar", "youtube": "youtube", "twitter": "twitter", "facebook": "facebook", "instagram": "instagram",
                "linear": "rss", "digital": "video camera", "highlights": "announcement"
            }

        };

    }


    mapNamesToIcons = (name) => {

    }

    createTable = (channelName, data) => {
        var tableHeaders = ['likes', 'comments', 'meScore'];
        return (
            <Segment inverted style={{ boxShadow: '0px 3px 4px 0px', fontFamily: 'helvatica' }}>
                <Label style={{ backgroundColor: channelToColor(channelName) }} content={channelName} size={'large'} />
                <Table basic='very' inverted celled collapsing style={{ fontSize:(isMobile)?"1em": window.innerWidth / 4 / 4 / 100 + "em" }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell><Icon name={this.state.channelToIcon["channelType"]} /></Table.HeaderCell>
                            <Table.HeaderCell><Icon name={'world'} /></Table.HeaderCell>
                            {
                                _.map(tableHeaders, (header) => <Table.HeaderCell><Icon name={this.state.channelToIcon[header]} /></Table.HeaderCell>)
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            _.map(_.keys(data), (channelType) => {
                                return _.map(_.keys(data[channelType]), (media) => {
                                    // console.log(channelType, media)
                                    return (<Table.Row>
                                        <Table.Cell>{channelType}</Table.Cell>
                                        <Table.Cell><Icon name={this.state.channelToIcon[media]} /></Table.Cell>
                                        {_.map(tableHeaders, (header) => {

                                            return <Table.Cell>{_.isUndefined(data[channelType][media][header]) ? 0 : numericConvertor(data[channelType][media][header])}</Table.Cell>
                                        })}</Table.Row>
                                    )
                                })
                            })
                        }
                    </Table.Body>
                </Table>
            </Segment>
        )

    }

    createGrid = () => {

        return (

            <Grid columns={4} style={{ marginLeft: '2rem', marginTop: '5rem' }} stackable>
                <Header style = {{color: 'white'}}>Collection MetaData</Header>

                <Grid.Row>
                    {
                        _.keys(this.props.data).map((channelName) => {

                            return (
                                <Grid.Column>
                                    {this.createTable(channelName, this.props.data[channelName])}
                                </Grid.Column>
                            )
                        })
                    }


                </Grid.Row>
            </Grid>
        )
    }


    render() {
        return this.createGrid();
    }
}



export default CollectorCard;