import _ from 'lodash'

var numericConvertor = function convertNumberToCommaFormat(value){
    switch (true) {
        case isNaN(value): return value; break;
        case value < 999: return _.round(value); break
        case value < 1000000: return Math.round(value / 1000) + "K"; break
        case value < 10000000: return (value / 1000000).toFixed(2) + "M"; break
        case value < 1000000000: return Math.round((value / 1000000)) + "M"; break
        case value < 1000000000000: return Math.round((value / 1000000000)) + "B"; break
        case value > 1000000000000: return "1T+"; break;
    }
}

export {numericConvertor}