import React from 'react'
import _ from 'lodash'
import './3d-login.css'
import MenuBar from './MenuBar';
import NavBar from './NavBar';
import { withRouter } from 'react-router-dom'
import { Button, Message, Loader, Dimmer } from 'semantic-ui-react'
import { deviceDetails } from '../Common/DeviceDetect'
import { Balerion } from '../BlueMoon'
import ProductGrid from './ProductGrid'
import { Dimple } from '../BlueMoon'
import {bToC} from '../Common/BrandToIcon'
import axios from 'axios'

class Login extends React.Component {

	constructor(props) {
		super(props);
		this.state = { userName: '', userPassword: '', error: false, ip: '' };
	}

	setField = (fieldName, event) => {
		this.setState({ [fieldName]: event.target.value })
	}

	callBackFunc = () => {
		this.setState({ userName: '', userPassword: '' });
	}

	componentDidMount = () => {

		axios.get(`https://api.ipify.org?format=json`).then((response) => {
			this.setState({ ip: response.data.ip });
		}, "json");
	}

	componentWillReceiveProps = () => {
		this.setState({ error: false })
	}

	checkUserExist = (userName, userPassword) => {
		this.setState({loader: true}, () => {

			axios({
				method: 'post',
				url: `${Balerion}token/generate-token`,
				headers: {},
				data: { "username": userName, "password": userPassword, "productName": "sponsorintel", "deviceName": deviceDetails.deviceName, "ip": this.state.ip, "osName": deviceDetails.osName, "browserVersion": deviceDetails.browserVersion }
			}).then((response) => {
				if (response.data.message === "success") {
					this.setState({ loader: false }, () => {
						localStorage.setItem('user', response.data.result.username);
						localStorage.setItem('token', response.data.result.token);
						localStorage.setItem('userId', response.data.result.userId);
						localStorage.setItem('userRole', response.data.result.role);
						var companyLogo= document.URL.split("/#/")[1];
						localStorage.setItem("companyLogo", companyLogo);
						this.props.history.push('home');
						this.setState({"flag": "true"});
					});
	
				} else {
					this.setState({
						loader: false,
						error: true
					})
				}
			}).catch((err) => {
				this.setState({
					loader: false,
					error: true
				})
			});
		})
		
	}

	render() {
		
		var currentUrl = document.URL;
		var isHome = (currentUrl.split("/#/")[1] === "home") ? true: false;
		var imageSrc = bToC(currentUrl.split("/#/")[1]);
		
		
		if(isHome)
			return <MenuBar tempFunc={this.callBackFunc} />
		
		if (!_.isEmpty(localStorage.getItem('projectIp'))) return (
			
			<div>
			<MenuBar tempFunc={this.callBackFunc} />
			<NavBar/>
			</div>
		);

		if (!_.isEmpty(localStorage.getItem('user'))) 
			return <MenuBar tempFunc={this.callBackFunc} />
		
		return (
			<div>
								 <Dimmer active = {this.state.loader}>
        <Loader indeterminate>verifying credentials ...</Loader>
      </Dimmer>

			<div className={"limiter"} style={{
				backgroundColor: `rgb(18, 18, 18)`,
			}}>
				<div className={"container-login100"} >
					<div className="wrap-login100" style={{ marginBottom: '10rem', backgroundColor: 'black', opacity: '0.7' }}>
						<form className="login100-form validate-form">
							<div style = {{borderRadius: '50%'}}>
							<img id = {'logo'}  src={imageSrc} className = {'login100-form-logo'}
								
							>
								
							</img>
							</div>
							<div className="wrap-input100 validate-input" data-validate="Enter username" style={{ color: 'white' }}>
								<input className="input100" type="text" name="username" placeholder="Username" value={this.state.userName} onChange={this.setField.bind(this, "userName")} style={{ color: 'white' }} />
								<span className="focus-input100" data-placeholder="&#xf207;"></span>
							</div>

							<div className="wrap-input100 validate-input" data-validate="Enter password">
								<input className="input100" type="password" name="pass" placeholder="Password" value={this.state.userPassword} onChange={this.setField.bind(this, "userPassword")} style={{ color: 'white' }} />
								<span className="focus-input100" data-placeholder="&#xf191;"></span>
							</div>

							<div className="contact100-form-checkbox">
								<input className="input-checkbox100" id="ckb1" type="checkbox" name="remember-me" />
								<label className="label-checkbox100" for="ckb1">
									Remember me
						</label>
							</div>


						</form>
						<Button circular size={'large'} style={{ marginLeft: '10rem' }} color={'teal'} onClick={() => {
							this.checkUserExist(this.state.userName, this.state.userPassword)
						}}>
							Login
						</Button>
						<Message negative style={{ visibility: (this.state.error) ? 'visible' : 'hidden' }}>
							<Message.Header>Wrong username or password retry !!</Message.Header>
						</Message>
					</div>
				</div>

			</div>

			</div>


		)
	}
}

export default withRouter(Login);