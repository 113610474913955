import React from 'react'
import _ from 'lodash'
import { Form, Button, Dimmer, Loader } from 'semantic-ui-react'
import axios from 'axios';
import { BlueMoon } from '../BlueMoon';
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

class AddForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collectionName: "",
            collectionDescription: "",
            isCollectionEmpty: false,
            isCollectionDescEmpty: false,
            eventName: 'La kings oct-2019',
            dimmer: false
        };
    }

    onChange = (name, e) => {
        this.setState({ [name]: e.target.value });
    }

    postMeta = () => {

        axios.post(
            `${BlueMoon.ip}collectionUpdate/`,
            {
                eventName: this.state.eventName,
                collectionName: this.state.collectionName.split(" ").join("-"),
                collectionDescription: this.state.collectionDescription,
                user: "temp"
            }
        ).then((response) => {
            if (response.data.result) {
                this.props.notificationService("Collection", "created successfully!!", "success");
                this.setState({ dimmer: false }, () => {
                    this.props.modelClose();
                }, () => {
                })
            }
            else {
                this.props.notificationService("Failed", "no group formed!!", "danger");
                this.setState({ dimmer: false })
            }

        }).catch((err) => {

        })
    }




    updateGroup = (filteredQuery) => {
        var param = Object.assign(filteredQuery, {
            userName: 'temp',
            groupName: this.state.collectionName.split(" ").join("-")
        });
        this.setState({ dimmer: true }, () => {
            axios.get(`${BlueMoon.ip}QCGroup/add`,
                {
                    params: param
                }
            ).then((response) => {
                if (response.data.update)
                    this.postMeta();
                else {
                    this.props.notificationService("Failed", "select a project !!", "danger");
                    this.props.modelClose();
                    this.setState({ dimmer: false })
                }
            }).catch((err) => {
                this.setState({ dimmer: false })

            })
        })
    }

    checkGroupAlreadyExists = () => {
        axios.get(`${BlueMoon.ip}groupCheckup/${this.state.collectionName}/${'temp'}`).then((response) => {
            if (!response.data.result) {
                this.updateGroup(this.props.filteredQuery);
            }
            else
                this.setState({ isCollectionEmpty: true }, () => this.props.notificationService("Group", "already exists", "warning"));
        }).catch((err) => {
        })
    }

    onPost = () => {
        this.setState({ isCollectionEmpty: _.isEmpty(this.state.collectionName), isCollectionDescEmpty: _.isEmpty(this.state.collectionDescription) });
        if (!this.state.isCollectionDescEmpty && !this.state.isCollectionEmpty) {
            this.checkGroupAlreadyExists();

        }
    }

    render() {
        if (this.state.dimmer) return <Dimmer active={this.state.dimmer}>
            <Loader />
        </Dimmer>
        return (

            <Form style={{ marginLeft: (isMobile) ? '3rem' : '0rem' }}>

                <Form.Group widths='equal'>
                    <Form.Field error={this.state.isCollectionEmpty}>
                        <label>Collection Name</label>
                        <input type="text" placeholder={_.isEmpty(this.state.collectionName) ? "Enter collection name" : this.state.collectionName} onChange={this.onChange.bind(this, "collectionName")} />
                    </Form.Field>
                    <Form.Field error={this.state.isCollectionDescEmpty}>
                        <label>Collection Description</label>
                        <input type="text" placeholder={_.isEmpty(this.state.collectionDescription) ? "Enter description" : this.state.collectionDescription} onChange={this.onChange.bind(this, "collectionDescription")} />
                    </Form.Field>
                </Form.Group>
                <Button color={'green'} onClick={this.onPost}>{'save'}</Button>
            </Form>
        )
    }
}

export default withRouter(AddForm);