var bToC = function brandToColor(brand){
    switch(brand){
        case 'dtm': return require('../Login/images/dtm.jpeg');
        case 'afl': return require('../Login/images/afl.jpeg')
        default: return require('../Login/images/wassman.jpg');
    }
}

export {bToC}

