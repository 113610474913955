import React from 'react'
import { BlueMoon } from '../BlueMoon'
import axios from 'axios'
import _ from 'lodash'
import './userLogs.css'
import TableTemplate from './TableTemplate'

class UserLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { logs: [], colors: ["#161A1C", "#4C464F"] };
    }

    fetchUserLogs = () => {
        axios.get(`${BlueMoon.ip}logs`).then((response) => {
            if (!_.isEmpty(response.data))
                this.setState({
                    logs: response.data
                });
        })
    }

    componentDidMount = () => {
        this.fetchUserLogs();
    }

    componentDidUpdate = () => {
        if (!_.isEmpty(document.getElementsByTagName('tr'))) {
            var thElements = document.getElementsByTagName('tr')[0].getElementsByTagName('th');
            for (var i = 0; i < thElements.length; i++)
                thElements[i].setAttribute('style', 'font-size: 1.1em;color: chartreuse !important;font-family: sans-serif;paddingLeft:0em;paddingRight:0em');
        }
    }


    render() {
        if (_.isEmpty(this.state.logs)) return null;

        if (_.isEqual(localStorage.getItem('permissions'), 'super-user'))
            return (
                <div style={{fontFamily: 'sans-serif', backgroundColor: '#161A1C' }}>
                    <TableTemplate data = {this.state.logs} serviceCaller = {"logs"} columns = {_.keys(this.state.logs[0]).reverse()}/>
                </div>
            )
        return null;
    }

}

export default UserLogs