import React from 'react'
import { Dropdown, Input, Icon, Modal, Image } from 'semantic-ui-react'
import _ from 'lodash'

class RowComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modelOpen: false, row: this.props.row, isDeleted: this.props.isDeleted, index: this.props.index, backgroundColor: this.props.backgroundColor }
    }


    cellsForRow = (row, index) => {
        return _.keys(row).map((key) => {
            var value = _.isNumber(row[key]) ? _.round(row[key]) : row[key]
            if (!(_.isEqual(key, "project") | _.isEqual(key, "mediaUrl") | _.isEqual(key, "id")))
                return <td style={{ fontFamily: 'sans-serif', padding: '0 0 0 0 px', textAlign: 'center', verticalAlign: 'middle' }} textAlign={'center'} verticalAlign={'middle'}>

                    {( (key === "brand" || key === "location") && this.state.edit ) ? <Dropdown style={{ pointerEvents: (this.state.isDeleted) ? "none" : "visible" }} placeholder={value} value={value} onChange={(e, data) => {
                        var row = this.state.row;
                        row[key] = data.value;
                        this.setState({ row: row })


                    }} clearable options={(key === "brand") ? this.props.brandNames : this.props.brandToAssetMapping[this.state.row['brand']].map((x) => { return { key: x, value: x, text: x } })} selection />
                        :
                        (key === "meScore" && this.state.edit) ? <Input style={{ pointerEvents: (this.state.isDeleted) ? "none" : "visible" }} size={'small'} onChange={(e) => {
                            var value = e.target.value;


                            var row = this.state.row; row['meScore'] = value.slice(1, value.length);
                            this.setState({ row: row }, () => {

                            })

                        }} focus value={"$" + value} /> : (value === null)?0:value
                    }
                </td>
        });
    
    }

    componentWillReceiveProps = (nextProps, currentProps) => {

        this.setState({ edit: nextProps.edit, modelOpen: false, row: nextProps.row, isDeleted: nextProps.isDeleted, index: nextProps.index, backgroundColor: nextProps.backgroundColor });
    }

    render() {
        var index = this.state.index;
        var existInDeleteList = this.state.isDeleted;
        var row = _.cloneDeep(this.state.row);

        delete row['_id']
        return <tr style={{ backgroundColor: this.props.backgroundColor, opacity: (this.state.isDeleted) ? 0.2 : 1 }}>
            <td> {(this.state.edit)? <Icon name={existInDeleteList ? "add square" : "trash"}  onClick={() => {

                this.setState({ isDeleted: !this.state.isDeleted }, () => {
                    if (this.state.isDeleted)
                        this.props.deleteRow(this.state.row._id)
                    else
                        this.props.addRow(index)
                })

            }}  style={{ opacity: 1, padding: '2em', cursor: 'pointer', color: (existInDeleteList) ? 'green' : 'grey' }} />: ""} </td>
            {this.cellsForRow(row, index)}

            <td><Image
                className={'dbData'}
                onClick={() => {
                    this.setState({ modelOpen: true, selectedImage: row['mediaUrl'] })
                }}
                style={{ border: '3px solid black', borderRadius: '4px', width: '50' }}
                src={row['mediaUrl']}
                width={"50"}
                height={"50"}

            /></td>
            <Modal open={this.state.modelOpen} centered={false} closeIcon onClose={() => {
                this.setState({ modelOpen: false })
            }}>
                <Modal.Content image style={{ backgroundColor: '#333' }}>
                    <Image src={this.state.selectedImage} style={{ width: '100%', height: '100%' }} />
                </Modal.Content>
            </Modal>
        </tr>
    }
}

export default RowComponent;
