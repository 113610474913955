import {Table, Dropdown, Icon} from 'semantic-ui-react'
import React from 'react'
import _ from 'lodash'
import {timeFormatter} from '../Common/CurrentTimeStamp'
class TableTemplate extends React.Component{
    constructor(props){
        super(props);
        this.state = {data: this.props.data, backupData: this.props.data, colors: ["#161A1C", "#4C464F"], serviceCaller: this.props.serviceCaller, status: false, 
        clockedIn: false, clockedOut: false, ip: false, userName: false, username: false, duration: false, analytics: false, dashboard:false, insights: false, rawdata: false, report: false, analysis: false
        , columns: this.props.columns
    };

    }


     isNumeric = (value)=> {
        return /^-{0,1}\d+$/.test(value);
    }

    sort = (header) => {
        var data = (!this.state[header])?_.sortBy(this.state.data, (o) => {return  o[header]}): _.reverse(this.state.data);

        this.setState({data:data,    [header]: !this.state[header]})
    }


    onSelect = (fieldName, key, value) => {
        this.setState({
            data: (value.value === "all")?this.state.backupData:this.state.backupData.filter((row)=> {
                return (row[fieldName] === value.value)
            })
        })
    }

    render(){
        var data = this.state.data;
        var colors = this.state.colors;
        var serviceCaller = this.state.serviceCaller;
    return (<Table inverted className={'logs'} >
    <Table.Header>
        <Table.Row>
            {    
                        this.state.columns.map((header) => {
                            console.log(header)
                                if(_.isEqual(header.toLowerCase(), "username"))
                                return <Table.HeaderCell textAlign = {'center'}> <Dropdown text= {'User'}
                                floating
                                labeled
                                button
                                onChange={this.onSelect.bind(this, header)}
                                options={_.uniq(this.state.backupData.map((data) => { return data[header] })).map((row) => {
                                    return { key: row, value: row, text: row };
                                }).concat({ key: "all", value: "all", text: "all" })}
                                className='icon'
                                style={{  backgroundColor: '#333', color: "chartreuse", paddingLeft: '1em !important'}}
                
                            />    </Table.HeaderCell>
        
                                return <Table.HeaderCell  verticalAlign = {'middle'} textAlign = {'center'}>{_.capitalize(header)}<Icon onClick = {this.sort.bind('userName', header)} name = {(this.state[header])?'caret down': 'caret up'}  /></Table.HeaderCell>
                            
                            })
                    }
                    
                

        </Table.Row>
    </Table.Header>

    <Table.Body>
        {
            _.map(data, (row, index) => {
                return (
                    <Table.Row style={{ backgroundColor: colors[(index + 1) % 2] }}>
                        {
                            _.map(this.state.columns, (value) => {
                                
                                if(_.isEqual(serviceCaller, "logs") && this.isNumeric(row[value])) 
                                    return <Table.Cell  verticalAlign = {'middle'} textAlign = {'center'}>{row[value]}min</Table.Cell>
                                else if(_.isEqual(serviceCaller, "behaviour") && !_.isNaN(parseInt(row[value])) )
                                return <Table.Cell verticalAlign = {'middle'} textAlign = {'center'}>{row[value]}s</Table.Cell>
                                else if(_.isEqual(value, "loggedOutTime") || _.isEqual(value, "loggedTime"))
                                {
                                    console.log(row[value])
                                    var date = new Date(row[value] + " UTC");
                                return <Table.Cell  verticalAlign = {'middle'} textAlign = {'center'} >{(row[value] === null)?null: timeFormatter(date)}</Table.Cell>}
                                else
                                return <Table.Cell  verticalAlign = {'middle'} textAlign = {'center'} >{row[value]}</Table.Cell>

                            })
                        }
                    </Table.Row>
                )
            })
        }

    </Table.Body>
</Table>);

    }
}


export default TableTemplate;