

 var channelToIcon =  {
    "channelType": "star", "likes": "thumbs up", "comments": "comment", "meScore": "dollar", "youtube": "youtube", "twitter": "twitter", "facebook": "facebook", "instagram": "instagram",
    "linear": "video camera", "digital": "tv", "highlights": "star"
}

function mapChannelToIcon(name) {
    return channelToIcon[name.toLowerCase()]
}

var channelToIconMapper = mapChannelToIcon

export {channelToIconMapper}