import React from 'react'
import { Grid, Statistic } from 'semantic-ui-react';
import './channelgradients.css'
import { numberWithCommas } from '../Common/NumberWithComas';
import _ from 'lodash'
import axios from 'axios'
import { BlueMoon, RequestToken } from '../BlueMoon';
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect';
import {getClassName} from '../Common/ProductToColor'

class ChannelGradientCards extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
    }


    getTotal = (data) => {
        return _.round(data.reduce((prev, curr) => {
            
            return _.toNumber(curr['value']) + prev;
        }, 0));
    }

    calculatePercent = (data) => {
        var total = this.state.total;
        console.log(total, "value")
        return data.map((row) => {
            row['percent'] = _.round(_.toInteger(row['value']) / total, 2);
            // console.log(row['percent']);
            return row;
        })
    }

    componentDidMount = () => {
        axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`}
        axios.get(`${BlueMoon.ip}dashboard/channelContrib/`).then((response) => {
            this.setState({
                total: this.getTotal(response.data.channelContrib)
            }, () => {
                this.setState({
                    data: this.calculatePercent(response.data.channelContrib)
                })
            })
        }).catch((err)=>{
            RequestToken();
        });;
    }


    createCard = (value, channelPercent, channelName) => {
        
        

        return (<Grid className = {getClassName(channelName)} style={{ marginLeft: '0.1rem', borderRadius: '', color: 'black', marginTop: '0.1rem', height: '12vh'}}>
            <Grid.Row>
                <Grid.Column width={(channelName === "sponsorship value") ? 16 : 11} style={{ fontWeight: '900', fontFamily: 'sans-serif', fontSize:(isMobile)?"1em": _.round(window.innerWidth/5/2/100, 2)+"em" }}>{(channelName === "sponsorship value")? "Sponsorship Value":_.capitalize(channelName)}</Grid.Column>
                {(channelName !== "sponsorship value") ? <Grid.Column width={5}   style={{padding: '0px', textAlign: 'right', right:'0.1em', fontSize: (isMobile)? "1em": 0.56* _.round(window.innerWidth/3/2/100, 2)+"em"}}>{_.round(channelPercent, 2)+"%"}</Grid.Column> : null}
            </Grid.Row>

            <Grid.Row>
                <Grid.Column width={16} style = {{position: 'absolute', bottom: '4px'}}> <Statistic>
                    <Statistic.Value style={{ color: 'black' }}>${numberWithCommas(_.round(value))}</Statistic.Value>
                </Statistic>
                </Grid.Column>
            </Grid.Row>
        </Grid>);
    }

    render() {
        if (_.isEmpty(this.state.data)) return null;

        return (
            <Grid style={{ marginRight: '1rem' }}  stackable>
                <Helmet>

                    <style type="text/css">{`
.ui.statistic>.value, .ui.statistics .statistic>.value {
    font-size: ${(isMobile)?"1em":_.round(window.innerWidth/3/3/100, 2)+"em"} !important;
}    

`}</style>
                </Helmet>
                <Grid.Row columns={5} >
                    <Grid.Column > {this.createCard(this.state.total, 100, "sponsorship value")}</Grid.Column>

                    {
                        _.map(this.state.data, (channel) => {
                            // console.log(channel.percent)
                            return (
                                <Grid.Column > {this.createCard(channel.value, channel.percent * 100, _.lowerCase(channel.name))}</Grid.Column>

                            )
                        })
                    }

                </Grid.Row>
            </Grid>)
    }
}

export default ChannelGradientCards;