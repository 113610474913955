import React from 'react'
import _ from 'lodash'
import {BlueMoon, Balerion, Dimple} from '../BlueMoon'
import axios from 'axios'
import {Grid, Image, Dimmer, Loader} from 'semantic-ui-react'
import './product-grid.css'
import NavBar from './NavBar';
import {withRouter} from 'react-router-dom'

class ProductGrid extends React.Component{
    constructor(props){
        super(props);
        this.state = {restrictedProductInfo: [], 'rerender': false}
    }

    componentDidMount = () => {
        console.log("component did mount called")
        var imageLogo = document.getElementById("logo");
        imageLogo.style.visibility = "hidden";
        var projectIp = localStorage.getItem('projectIp');
        if(!_.isEmpty(localStorage.getItem("userId"))){
            axios.get(`${Balerion}project_list/${localStorage.getItem("userId")}`).then((response) => {
            this.setState({restrictedProductInfo: response.data});
            });
        }
    }

    onClickProduct = (productInfo, event) => {
        console.log(productInfo);
        localStorage.setItem('currentProduct', productInfo[1]);
        localStorage.setItem('projectIp', productInfo[2]);
        BlueMoon.ip = productInfo[2] + "/";
        var imageLogo = document.getElementById("logo");
        localStorage.setItem("logoSrc", productInfo[0]);
        imageLogo.src = productInfo[0];
        imageLogo.style.visibility = "visible";
        this.props.history.push('/dashboard/');
    }

    render(){
        if(_.isEmpty(localStorage.getItem('user'))) window.location.replace(`${Dimple}#/${localStorage.getItem('companyLogo')}`);
        
        if(!_.isEmpty(this.state.restrictedProductInfo))
        return   <Grid columns={3} divided style = {{marginLeft: ' 5rem', marginTop: '6rem'}} stackable = {true}>
            {
                _.map((this.state.restrictedProductInfo), (productInfo) => {
                    return <Grid.Column>
                        <Image className = {'zoom'} src={productInfo[0]} width = {'250px'} height = {'250px'}   onClick = {this.onClickProduct.bind(this, productInfo)}/>
                    </Grid.Column>
                })
            }
            </Grid>

    return <Dimmer active>
        <Loader indeterminate>Loading your projects !!</Loader>
      </Dimmer>
    
}
}

export default withRouter(ProductGrid);