import React from 'react'
import axios from 'axios';
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import ComparisonGraph from './ComparisonGraph'
import { BlueMoon, RequestToken } from '../BlueMoon'
import { Table, Button, Modal, Dimmer, Loader, Dropdown } from 'semantic-ui-react';
import './3d-transform.css';
import './comparison.css'
import { isMobile } from 'react-device-detect';


class GroupComparison extends React.Component {
    constructor(props) {
        super(props);
        this.state = { dataLoaded: false, isBarGraph: false, groupInfo: [], modalOpen: false, selectedGroups: new Set(), columns: ['collectionName', 'eventName', 'eventDescription', 'createdDate'] };
    }

    componentDidMount = () => {

        axios.get(`${BlueMoon.ip}getCollections/${'temp'}`).then((response) => {
            if (!_.isEmpty(response.data))
                this.setState({ groupInfo: response.data })
            this.setState({ dataLoaded: true })
        }).catch((err) => {
            RequestToken();
        })
    }

    setMouseHover = (index, color, scaleValue) => {
        document.getElementsByClassName('zoom')[index].onmouseover = function () {
            this.style.backgroundColor = color;
            this.style.transform = `scale(${scaleValue})`;
        }
    }

    setMouseOut = (index, color, scaleValue) => {
        document.getElementsByClassName('zoom')[index].onmouseout = function () {
            this.style.backgroundColor = color;
            this.style.transform = `scale(${scaleValue})`;
        }
    }

    changeColorOfSelectedRow = (index, elementExists) => {
        if (!elementExists) {
            this.setMouseHover(index, "green", 1.0);
            this.setMouseOut(index, "green", 1.0);
        } else {
            this.setMouseHover(index, "teal", 1.05);
            this.setMouseOut(index, "#333", 1.0);
        }
    }


    onSelectGroup = (index) => {
        var collectionName = this.state.groupInfo[index].collectionName;
        // console.log(collectionName)
        // console.log(Array.from(this.state.selectedGroups));
        var elementExists = Array.from(this.state.selectedGroups).includes(collectionName);
        console.log(elementExists)
        this.changeColorOfSelectedRow(index, elementExists);
        var temp = this.state.selectedGroups;
        if (!elementExists)
            temp.add(collectionName);
        else
            temp.delete(collectionName);
        this.setState({ selectedGroups: temp })
    }

    createTable = (data) => {
        if (_.isEmpty(data)) return null;
        return (
            <Table inverted style={{ height: '100%' }} className={'comparisonTable'}>
                <Table.Header>
                    <Table.Row>
                        {
                            _.map(this.state.columns, (value) => {
                                return (
                                    <Table.HeaderCell >{value.replace(/([A-Z])/g, ' $1').split(' ').map((x) => _.capitalize(x)).join(" ")}</Table.HeaderCell>
                                )
                            })
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body>

                    {
                        _.map(data, (row, index) => {

                            return (
                                <Table.Row className="zoom" onClick={() => {
                                    this.onSelectGroup(index);
                                }}>

                                    {_.map(this.state.columns, (key) => {
                                        return (
                                            <Table.Cell style={{ padding: '1.5em !important' }} >{_.capitalize(row[key])}</Table.Cell>
                                        )
                                    })}
                                </Table.Row>
                            );
                        })
                    }
                </Table.Body>
            </Table>)
    }



    render() {
        if (_.isEmpty(localStorage.getItem('user'))) this.props.history.push('./login')

        if (!this.state.dataLoaded) return <Dimmer active={true} >
            <Loader />
        </Dimmer>;

        return (
            <div style={{ marginTop: '5rem', marginLeft: (isMobile) ? '4rem' : '5%', marginRight: '2rem' }}>

                {this.createTable(this.state.groupInfo)}
                <Modal style={{ height: '90%', width: '90%', backgroundColor: '#161A1C' }} open={this.state.modalOpen} trigger={<Button disabled={_.isEmpty(this.state.groupInfo) ? true : false} onClick={() => {
                    this.setState({ modalOpen: true })
                }} color={'blue'} style={{ float: 'right' }}>{'Compare'}</Button>} closeIcon onClose={() => { this.setState({ modalOpen: false }) }}>
                    <Modal.Header style={{ textAlign: 'center', backgroundColor: '#161A1C', color: 'white', paddingBottom: '1px', fontFamily: 'sans-serif !important', fontWeight: '900' }}>{"Comparison Module"}</Modal.Header>

                    <Modal.Content style={{ backgroundColor: '#161A1C', padding: '0' }}>

                        {(this.state.modalOpen && (this.state.selectedGroups.size) > 1) ? <ComparisonGraph selectedGroups={Array.from(this.state.selectedGroups)} /> : null}
                    </Modal.Content>
                </Modal>

            </div>);

    }
}
export default withRouter(GroupComparison);