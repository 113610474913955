import React from 'react'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { Button, Icon } from 'semantic-ui-react'
import _ from 'lodash'
import {BlueMoon, RequestToken} from '../BlueMoon'
import axios from 'axios'
import $ from 'jquery'

class AWSDatePicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = { startDate: new Date(), endDate: new Date(), restrictedDays: [], calendar: false, maxDate: new Date()};
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);

    }
    setWrapperRef(node) {
        this.wrapperRef = node;
      }
    componentDidMount = () => {
        axios.get(`${BlueMoon.ip}eventDateRange`).then((response)=>{
            
            this.setState({
                restrictedDays: response.data[0], maxDate: new Date(response.data[0]['maxDate'])
            })
        }).catch((err)=>{
            RequestToken();
        });
        document.addEventListener('click', this.handleClickOutside, true);


    }

     isDescendant = (parent, child) => {
        var node = child.parentNode;
        while (node != null) {
            if (node == parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
   }

    handleClickOutside = (event) =>{
   
        var doc = document.getElementById("calendar")
        var present = this.isDescendant(doc, event.target)
       
        if (event.target.id!="calendarIcon" && !present) {

            var doc = document.getElementById("calendar")
            if(!_.isNull(doc)){
            if(doc.style.visibility === "visible")
                {
                this.setState({
                    calendar: false
                })
            }
        }}
      
    
    }
    

      
    componentDidUpdate = () => {
        var barTop = document.getElementsByClassName('rdrDateDisplay')[0];
        var calendarDays = document.getElementsByClassName('rdrWeekDay');
        var inputTag = document.getElementsByTagName('input')
        var selectTag = document.getElementsByTagName('select');
        for (var z = 0; z < inputTag.length; z++)
            inputTag[z].style.color = 'black'
        for (var j = 0; j < selectTag.length; j++)
            selectTag[j].style.color = 'yellow';
        for (var i = 0; i < calendarDays.length; i++)
            calendarDays[i].style.color = 'violet'
        var daysContainer = document.getElementsByClassName('rdrMonths rdrMonthsVertical')[0];
        // daysContainer.append()
        if(!_.isUndefined(barTop))
        barTop.style.backgroundColor = 'black';
        var months = document.getElementsByClassName("rdrMonthPicker")
        if(months.length > 0){
        var options = months[0].getElementsByTagName("select");
        console.log(options)
        for(var i=0; i<options.length; i++)
        {options[i].style.color = 'yellow'
        options[i].style.backgroundColor = 'black'
        }
    }
    }

    dateFormat = (selectedDate) => {
        return `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`
    }


    getInitialState() {
        if (!_.isUndefined(this.props.placeholder)) {
            var eventDates = this.props.placeholder.eventDates;
            return {
                from: (_.isEmpty(eventDates)) ? undefined : eventDates[0],
                to: (_.isEmpty(eventDates)) ? undefined : eventDates[1],
            };
        }
        return {
            from: undefined,
            to: undefined
        }

    }

    handleResetClick() {
        this.setState(this.getInitialState(), () => {
            if (_.isUndefined(this.props.eventFilter)) {
                this.props.placeholder['eventDates'] = [];
                this.setState({ from: undefined, to: undefined, calendar: !this.state.calendar }, () => {
                    this.props.selectFilter({ "event_date": "all"}, this.props.placeholder, false, true);

                })
            }
            else
                this.setState({ to: undefined, from: undefined, calendar: !this.state.calendar}, () => this.props.selectFilter("eventDates", []));
        });
    }

    apply = () => {
        if (this.state.from && this.state.to) {
            if (_.isUndefined(this.props.eventFilter)) {
                this.props.placeholder['eventDates'] = [(this.state.from), (this.state.to)]
                    
                this.setState({ calendar: !this.state.calendar }, () => this.props.selectFilter({ event_date: `${this.dateFormat(this.state.from)},${this.dateFormat(this.state.to)}` }, this.props.placeholder, false, true));
            }
            else {
                this.setState({ calendar: !this.state.calendar }, () => this.props.selectFilter("eventDates", [this.dateFormat(this.state.from), this.dateFormat(this.state.to)]));
            }
        }
        else{
            this.handleResetClick();
        }
    }

    handleSelect = (ranges) => {
        // console.log(ranges);
        this.setState({
            from: ranges.selection.startDate,
            to: ranges.selection.endDate
        });

    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
      }

    render() {
        if(_.isEmpty(this.state.restrictedDays)) return null;

        if (_.isUndefined(this.state.to) && _.isUndefined(this.state.from))
        var selectionRange = {
            startDate: this.state.maxDate,
            endDate: this.state.maxDate,
            key: 'selection',
        }
        else
            var selectionRange = {
                startDate: this.state.from,
                endDate: this.state.to,
                key: 'selection',
            }
        return (
            <div style = {{textAlign: (_.isUndefined(this.props.eventFilter))?'center': 'left' }}>
               
               <i id = "calendarIcon" class="fa fa-calendar" aria-hidden="true" style={{ color: _.isUndefined(this.props.eventFilter) ? 'teal' : 'teal', cursor: 'pointer', fontSize: '1.6em', marginTop: '0.3rem'}}  onClick = {()=>{
                 var doc = document.getElementById("calendar")
                 if(doc.style.visibility === "hidden")
                    this.setState({
                        calendar: true
                    })
                else 
                this.setState({
                    calendar: false
                })
             }} ></i>

                {/* {console.log(localStorage.getItem('calendar'))} */}

                <div id="calendar" style={{ display: 'table-caption', backgroundColor: '#16091c', paddingBottom: '10px', marginTop: '1.3rem', position: 'absolute', visibility: (this.state.calendar)?'visible':'hidden', zIndex: 3 }}>
                <DateRangePicker
                minDate = {new Date(this.state.restrictedDays.minDate)}
                maxDate = {new Date(this.state.restrictedDays.maxDate)}
				ranges={[selectionRange]}
				onChange={this.handleSelect} />
                    <div>
                        <Button size={'small'} style={{ float: 'left', marginLeft: '1rem' }} color={'blue'} onClick={() => {
                            this.apply();
                        }}>Apply</Button>
                        <Button  style={{ float: 'right', marginRight: '1rem', cursor: 'pointer' }} size={'small'} color={'grey'} onClick={() => {
                            this.setState({ to: undefined, from: undefined }, ()=>{
                            })
                        }}> Cancel</Button>
                    </div>
                </div>
            </div>
        );
    }
}


export default AWSDatePicker;