import React from 'react'
import { Grid, Button} from 'semantic-ui-react'
import _ from 'lodash'
import {withRouter} from 'react-router-dom'
import BarChart3D from './BarChart3D'
import Speedometer from './Speedometer'
import BrandGrid from './BrandGrid'
// import ChannelGroup from './ChannelGroup'
import PieChart from './PieChart'
import FilterDashboard from './FilterDashboard'
// import BrandPortfolio from './BrandPortfolio'
import StackedBar from './StackedBar'
// import AssetPortfolio from './AssetPortfolio'
import AssetGrid from './AssetGrid'
import DBData from './DBData'
// import comparisonCard from './ComparisonCard'
import ComparisonCard from './ComparisonCard'
import { updateTimings } from '../UserBehaviour/UserBehaviourTimer'
import ChannelGradientCards from './ChannelGradientCards'
import 'react-picky/dist/picky.css';
import { Dimple, BlueMoon } from '../BlueMoon'
import TopMedias from './TopMedias'

class Dashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            href: document.location.href,
            dashBoardData: [], channel: [
                { key: 1, text: 'Social', value: 'Social' },
                { key: 2, text: 'Linear', value: 'Linear' },
                { key: 3, text: 'Highlights', value: 'Highlights' },
                { key: 4, text: 'Digital', value: 'Digital' }
            ], channelToColor: {
                "Social": '#4444A5',
                "Digital": "yellow",
                "Highlights": '#FF9027',
                "Linear": '#44A1A5',
            }, channelToIcon: {
                "Linear": "fa fa-video-camera",
                "Social": "fa fa-rss",
                "Digital": "fa fa-tv",
                "Highlights": "fa fa-star"
            }, params: {},
            filterDisplay: [], brands: [],
            totalDataCount: 0,
            filterGraphs: ['Bar', 'Line'],
            update: false,
            paramsToFetch: {}
        };
    }


    componentDidMount = () => {
        // console.log("component did mount of the dashboard is called");
        this.setState({ update: !this.state.update })
    }

    componentWillReceiveProps = () => {
        this.setState({href: document.location.href})
        // console.log("comoponet will recieve of the dashboard is called");
    }
    increment_date = (current_date, days) => {
        var myDate = new Date(current_date);
        myDate.setDate(myDate.getDate() + days);
        var y = myDate.getFullYear(),
            m = myDate.getMonth() + 1, // january is month 0 in javascript
            d = myDate.getDate();
        var pad = function (val) { var str = val.toString(); return (str.length < 2) ? "0" + str : str };
        var dateString = [y, pad(m), pad(d)].join("-");
        return dateString;
    }

    setTimeLineData = () => {
        var timeLineData = []
        var counter = 365;
        var date = "1997-01-01"
        for (var i = 0; i < counter; i++) {
            date = this.increment_date(date, 1)
            timeLineData.push([date, Math.round(Math.random() * 100000)])

        }
        return timeLineData;
    }

    filterCharts = (selectedGraph) => {
        this.setState({ selectedGraph: selectedGraph });
    }

    fetchFilterData = () => {
        console.log("filtered data")
        this.setState({
            params: _.cloneDeep(this.state.paramsToFetch)
        })
    }
    
    selectFilter = (newParam, filter, shouldDelete, isDate) => {
        var paramsToFetch = this.state.paramsToFetch;
        if(shouldDelete === true)
            {
            delete paramsToFetch[newParam];
            this.setState({
                paramsToFetch: paramsToFetch
            });
        }
        else{

            if(_.isEqual(newParam, "reset"))
                this.setState({  params: {}, paramsToFetch: {}, dimmer: true, filterDisplay: [] });
            else
                this.setState({ paramsToFetch: Object.assign(this.state.paramsToFetch, newParam), dimmer: true, filterDisplay: filter }, ()=>{
                    if(isDate){
                        this.fetchFilterData();
                    } 
                }
                    );
        }
        
    }

    

    handleChange = date => {
        this.setState({
            startDate: date
        });
    };



 
    

    componentDidUpdate = () => {
        var updateElements = document.getElementsByClassName("ui attached tabular menu")[0];
        if (!_.isUndefined(updateElements)) {
            updateElements.style.border = '1px';
            updateElements = updateElements.getElementsByTagName("a");

            for (var i = 0; i < updateElements.length; i++) {
                if (i == 0)
                    updateElements[i].style.backgroundColor = 'chartreuse';
                else if (i == 1)
                    updateElements[i].style.backgroundColor = 'cyan';
                else
                    updateElements[i].style.backgroundColor = '#AF1B96';

                updateElements[i].style.color = 'black';
                updateElements[i].style.fontFamily = 'sans-serif';
                updateElements[i].style.fontWeight = 'bolder';
            }

        }
       var progress = document.getElementsByClassName("ui black tiny progress")
       for(var i=0; i<progress.length; i++){
           progress[i].style.margin = "0em"
           progress[i].style.marginTop = "0.2em"
           progress[i].style.backgroundColor = "#333"

       }

    }



    displayComponents = () => {
        return (
            <Grid stackable style={{}}>
                <Grid.Row style = {{padding: 0}}>
                    <Grid.Column width={12} stackable>
                     
                        <Grid.Row >
                            <BrandGrid params={_.cloneDeep(this.state.params)} />
                        </Grid.Row>
                    
                    <Grid.Row>
                                {(_.isEmpty(this.state.params.Brand) || this.state.params.Brand.split("<<!!>>").length === 1)?<TopMedias brand = {this.state.params.Brand}/>: null}
                    </Grid.Row>
                    </Grid.Column>


                    <Grid.Column width={4} textAlign={'left'}>
                           
                                    <AssetGrid params={_.cloneDeep(this.state.params)} />
                           
                    </Grid.Column>
                </Grid.Row>


            </Grid>

        )
    }

    dashBoard = () => {
        return (
            <div style={{ marginTop: '1rem', marginRight: '1rem' }}>
                
                
                        
                                <Grid  stackable>
                                    <Grid.Row style = {{width: '100%'}}>
                                        <div style = {{backgroundColor: ''}}/>
                                        <Grid.Column style = {{width: '74%'}}>
                                        <StackedBar channelToColor={this.state.channelToColor} params={_.cloneDeep(this.state.params)} />
                                        </Grid.Column>
                                        <Grid.Column width = {4} style = {{padding: 0}}>
                                        <PieChart params={_.cloneDeep(this.state.params)} />
                                        </Grid.Column>

                                    </Grid.Row>
                                </Grid>                               
                {this.displayComponents()}
                <Grid style = {{marginTop: `3rem`}}>
                <Grid.Row >
                            <Grid.Column width = {16}>
                                <ComparisonCard params={_.cloneDeep(this.state.params)} />
                            </Grid.Column>
                            
                        </Grid.Row>
                        </Grid>
                <div style={{ marginTop: '1rem', marginLeft: '30%', fontFamily: 'sans-serif', fontWeight: 'bolder', color: 'burlywood' }}>
                    Copyright © 2020 Alpha Cloud Labs,LLC. All Rights reserved.

            </div>
            </div>

        );
    }


getComponent = () => {
    var exprs = this.state.href.split('/');
    var expr = exprs[exprs.length-1]
    if( _.isEmpty(expr))
    return this.dashBoard();
    switch(expr){
        case "3D-VIZ": return <BarChart3D params={_.cloneDeep(this.state.params)} channelToColor={this.state.channelToColor} />
        case "rawdata": return <DBData params={_.cloneDeep(this.state.params)} totalDataCount={this.state.dashBoardData.totalDataCount} />
        case "dashboard" || _.isEmpty(expr): return this.dashBoard();
    }
}

 changeHref = (tab) => {
        updateTimings();
        this.props.history.push(`/dashboard/${tab}`)
    }


 
    tabView = () => {
        var isAdmin = localStorage.getItem("userRole") === "admin";
        return(
            <div style = {{marginLeft: '4rem', marginTop: '5rem', backgroundColor: 'black'}}>
                                <ChannelGradientCards/>
                                <div style = {{marginTop: '3rem'}}>
                <Button size = {'large'} content = {'Dashboard'}  style = {{marginRight: '0.1em'}} color = {'violet'} onClick = {this.changeHref.bind(this, 'dashboard')}/>
                <Button size = {'large'} content = {'3D-VIZ'} disabled = {!isAdmin} style = {{marginRight: '0.1em'}} color = {'teal'} onClick = {this.changeHref.bind(this, '3D-VIZ')}/>
                <Button size = {'large'} content = {'Rawdata'} disabled = {!isAdmin} style = {{marginRight: '0.1em'}} color = {'grey'} onClick = {this.changeHref.bind(this, 'rawdata')}/>
                </div>
                {
                    <div style = {{backgroundColor: 'black'}}>
                    <FilterDashboard selectFilter={this.selectFilter} displayFilter={this.state.filterDisplay} params={_.cloneDeep(this.state.paramsToFetch)} fetchFilterData = {this.fetchFilterData} style = {{marginRight: '0.1rem'}}/>
                  {this.getComponent()}
                  </div>
                }
            </div>
        )
    }
    render() {
        if(_.isEmpty(localStorage.getItem('user'))) window.location.replace(`${Dimple}#/${localStorage.getItem('companyLogo')}`);
        console.log(`${Dimple}#/home`)
        if (_.isEmpty(localStorage.getItem('projectIp')))  window.location.replace(`${Dimple}#/home`);
        // console.log(React.version)
        if (_.isEmpty(localStorage.getItem('user'))) return null;
        return this.tabView();
    }
}


export default withRouter(Dashboard);

