import React from 'react'
import { Card, Image, Icon, Checkbox, Grid, Modal, Button, Label } from 'semantic-ui-react'
import { numericConvertor } from '../Common/NumericConvertor'
import _ from 'lodash';
import { Helmet } from 'react-helmet'
import { channelToIconMapper } from '../Common/ChannelToIcon'
import { isMobile } from 'react-device-detect';

class MediaCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            isPlaying: false,
            volume: 0.7,
            selectedMedia: "",
            allMediaCheckBoxStatus: this.props.selectAllMedia,
            checkBoxStatus: this.props.selectAllMedia
        }
    }

    componentWillReceiveProps = (nextProps, currentProps) => {
        if (nextProps.selectAllMedia != this.props.selectAllMedia)
            this.setState({ checkBoxStatus: nextProps.selectAllMedia, open: false })

    }

    shouldComponentUpdate(prevProps, prevState) {

        return (prevState.checkBoxStatus != this.state.checkBoxStatus) || (this.state.open != prevState.open);
    }

    handlePlay = () => {
        this.setState({ isPlaying: true });
    };

    handlePause = () => {
        this.setState({ isPlaying: false });
    };

    handleVolume = value => {
        this.setState({ volume: value });
    };

    componentDidUpdate = () => {
        var iframes = document.getElementsByTagName("iframe");
    }

    cardMetaGrid = () => {
        var isChecked = false;
        if (this.props.selectAllMedia == true && this.state.checkBoxStatus == false) {
            isChecked = true;
        }
        var likes = numericConvertor(this.props.mediaDetail['likes']);
        var comments = numericConvertor(this.props.mediaDetail['comments']);
        var value = numericConvertor(this.props.mediaDetail['value']);
        return (

            <Grid relaxed columns={5} style={{ marginLeft: '0.1rem', marginBottom: '0.3rem' }}>
                <Grid.Row>
                    <Icon style={{ color: 'white' }} name={channelToIconMapper(this.props.mediaDetail['website'])} />
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={{ padding: '0em' }}>
                        <div style={{ flex: true }}>
                            <Icon name='thumbs up' color={'blue'} />
                            <span style={{ color: 'white', fontSize: `${window.innerWidth / 4 / 4 / 100 - 0.10}em` }}>{_.isUndefined(likes) ? "NA" : likes}&nbsp;&nbsp;</span>
                        </div>
                    </Grid.Column>
                    <Grid.Column style={{ padding: '0em' }}>
                        <div style={{ flex: true }}>
                            <Icon name="comment" color={'grey'} />
                            <span style={{ color: 'white', fontSize: `${window.innerWidth / 4 / 4 / 100 - 0.10}em` }}>{_.isUndefined(comments) ? "NA" : comments}</span>
                        </div>
                    </Grid.Column>
                    <Grid.Column style={{ padding: '0em' }}>
                        <Icon name={'dollar sign'} style={{ color: 'white', fontSize: (isMobile) ? "1em" : window.innerWidth / 4 / 4 / 80 + "em", fontWeight: '900', marginLeft: '0.5rem' }}>{_.isUndefined(value) ? "NA" : value}</Icon>
                    </Grid.Column>
                    <Grid.Column style={{ padding: '0em' }}>
                        {/* {console.log(this.props.selectAllMedia, " all media button is clickedS")} */}
                        {(!_.isUndefined(this.props.mediaDetail['mediaUrl'])) ? <Checkbox style={{ float: 'right' }} checked={this.state.checkBoxStatus} onClick={() => {
                            this.setState({ checkBoxStatus: !this.state.checkBoxStatus }, () => {
                                // console.log(this.state.checkBoxStatus);
                                this.props.selectMedia(this.props.mediaDetail);
                            })

                        }} /> : null}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }


    getOriginURl(postUrl, permanentLink, videoUrl, tweetLink) {
        switch (true) {
            case !_.isNull(postUrl): return postUrl;
            case !_.isNull(permanentLink): return permanentLink;
            case !_.isNull(videoUrl): return videoUrl;
            case !_.isNull(tweetLink): return tweetLink;
            default: return null
        }
    }

    createCard = () => {
        // console.log(this.props.mediaDetail)
        const { isPlaying, volume } = this.state;
        return (
            <Card style={{ backgroundColor: 'black', width: '100%', cursor: 'pointer' }} >
                <Image width={'100%'} height={'200vh'} src={_.isUndefined(this.props.mediaDetail['mediaUrl']) ? this.props.mediaDetail : this.props.mediaDetail['mediaUrl']} onClick={() => {
                    var postUrl = this.props.mediaDetail.postUrl;
                    var permanentLink = this.props.mediaDetail.permanentLink;
                    var videoUrl = this.props.mediaDetail.videoUrl;
                    var tweetLink = this.props.mediaDetail.tweetLink;
                    var url = this.getOriginURl(postUrl, permanentLink, videoUrl, tweetLink);
                    this.setState({
                        open: true,
                        originUrl: url,
                        selectedMedia: _.isUndefined(this.props.mediaDetail['mediaUrl']) ? this.props.mediaDetail : this.props.mediaDetail['mediaUrl']
                    })
                }} />

                <Card.Content extra>
                    {this.cardMetaGrid()}
                </Card.Content>
            </Card>)
    }

    openLinkNewTab = () => {
        window.open(this.state.originUrl, '_blank');
    }

    render() {
        return (

            <div>
                {this.createCard()}
                <Modal open={this.state.open} onClose={() => { this.setState({ open: false }) }} closeIcon>
                    <Modal.Header style={{ marginLeft: (isMobile) ? "4rem" : "0rem" }}>{this.props.mediaDetail.project} {(_.isNull(this.state.originUrl)) ? null : <Icon name={'arrow circle right'} style={{ float: 'right', cursor: 'pointer' }} onClick={this.openLinkNewTab} />} </Modal.Header>
                    <Modal.Content style={{ backgroundColor: '#333' }}>
                        <Image src={this.state.selectedMedia} style={{ width: '100%', height: '50vh' }} />
                    </Modal.Content>
                </Modal>
                <Helmet>
                    <style type="text/css">
                        {`iframe {
              width: 100% !important;
    height: 50vh !important;
    border: none !important;
}`}
                    </style>
                </Helmet>
            </div>
        )
    }
}

export default MediaCard;