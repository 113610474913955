var currentTimestamp = function getTimeStamp() {
    var d1 = new Date();
    return `${d1.getUTCFullYear()}-${d1.getUTCMonth()+1}-${d1.getUTCDate()} ${d1.getUTCHours()}:${d1.getUTCMinutes()}:${d1.getUTCSeconds()}`
}

var timeFormatter = function formatter(date){
    var d1 = date;
    return `${d1.getYear()+1900}-${d1.getMonth()+1}-${d1.getDate()} ${d1.getHours()}:${d1.getMinutes()}:${d1.getSeconds()}`
}

export {currentTimestamp, timeFormatter};