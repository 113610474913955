import React from 'react'
import { Card, Button, Icon, Input, TextArea } from 'semantic-ui-react'
import axios from 'axios'
import _ from 'lodash'
import {BlueMoon, RequestToken} from '../BlueMoon'
class InsightsFeedBack extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            insightsReport: [], colors: ['green', '#1B7FD3', 'purple', '#CF7216', 'brown'], 
            editActiveIndexes: []
        };
    }

    fetchReport = () => {
        axios.get(`${BlueMoon.ip}insights/feedback`).then((response) => {
            this.setState({
                insightsReport: response.data
            })
        }).catch((err)=>{
            RequestToken();
        })

    }

    componentDidMount = () => {
        this.fetchReport();
    }
 
    edit = (index, event) => {
        
        var activeIndexes = this.state.editActiveIndexes;
        if(!activeIndexes.includes(index))
            activeIndexes.push(index);
        else
            activeIndexes.splice(activeIndexes.indexOf(index), 1);

        this.setState({
            editActiveIndexes: activeIndexes
        });

    }
 
    updateFeedBack = ( index, value) => {

        var report = this.state.insightsReport;
        report[index]['feedBack'] = value;
        
        this.setState({
            insightsReport: report
        });

    }


    updateToDB = (index) => {
        axios(  {method: 'post', url : `${BlueMoon.ip}updateFeedBack`, data: this.state.insightsReport[index] }).then((response) => {
            var activeIndexes = this.state.editActiveIndexes;
            activeIndexes.splice(activeIndexes.indexOf(index), 1)
            if(response.data){
                this.setState({
                    editActiveIndexes: activeIndexes
                })
            }
        })
    }

    renderCard = () => {
        return (
            <Card.Group style={{ marginTop: '2rem', marginLeft: '2rem', marginRight: '0.1rem'}} itemsPerRow={5} stackable>
                {
                    this.state.insightsReport.map((data, index) => {
                        var editMode = this.state.editActiveIndexes.includes(index);
                        
                        return <Card style={{ boxShadow: "none", backgroundColor: this.state.colors[index], color: 'white' }}>
                            <Card.Content>
                                <Card.Header style={{ color: 'white', marginBottom: '4px', fontFamily: 'sans-serif'}}><div style = {{display: 'flex'}}><div style = {{width: '80%'}}>{"Insights: "}{index + 1}</div><Icon onClick = {
                             this.edit.bind(this, index)
                             } name = { (editMode)?"cancel":'pencil square' } style = {{float: 'right', cursor: 'pointer'}}/> </div></Card.Header>
                                <Card.Meta style={{ color: 'white' }}>
                                </Card.Meta>
                                <Card.Description style={{ color: 'white', fontFamily: 'sans-serif'}} >
                                    <div contentEditable = {editMode}  >
                                    {(editMode)?<TextArea style = {{width: '100%', backgroundColor: "black", height: '15vh', fontFamily: 'sans-serif', color: 'white'}} type = {'text'} value = {data.feedBack} onChange = {(e)=>{
                                        this.updateFeedBack(index, e.target.value)
                                    }} />:data.feedBack}
                                    </div>
                                </Card.Description>
                                <Button style = {{backgroundColor: 'grey', color: 'black', visibility: (editMode)? "visible": "hidden" , fontFamily: 'sans-serif', color: 'white'}} size = {'small'} onClick = {
                                    ()=>{ this.updateToDB(index)}
                                    } >{'save'}</Button>
                            </Card.Content>
                        </Card>


                    })}
            </Card.Group>)
    }

    render() {
        if (_.isEmpty(this.state.insightsReport)) return null;
        return this.renderCard();
    }

}

export default InsightsFeedBack;
