import _ from 'lodash'
import axios from 'axios';

        

    function getServiceName() {
       return localStorage.getItem("projectIp");
    }

    function getUiIp() {
        return _.isEqual(process.env.NODE_ENV, "development")?"http://localhost:3001/":"http://23.21.147.166/"
     }

     function getBalerionIp(){
         return _.isEqual(process.env.NODE_ENV, "development")?"http://23.21.147.166:7877/":"http://23.21.147.166:7877/"
     }

     function RequestNewToken(){
        var companyLogo = localStorage.getItem("companyLogo");
         localStorage.clear();
         window.location.href = `${getUiIp()}#/${companyLogo}`
     }

    console.log("called !! ")
    var Dimple = getUiIp();
    
    const BlueMoon = {}
    if(!(_.isEmpty(localStorage.getItem('projectIp') ) && localStorage.getItem('projectIp') != "undefined")){
        console.log("is getting assinged !! ")
        BlueMoon['ip'] = localStorage.getItem('projectIp') + "/";
    }
    var RequestToken = RequestNewToken;
    var Balerion = getBalerionIp();

export {BlueMoon, Dimple, RequestToken, Balerion};
