import _ from 'lodash'

var setTimer = function setTime(pageName) {
    localStorage.setItem('currentPage', pageName);
    localStorage.setItem('currentTime', new Date().getTime());
}

var resetTime = function resetTime() {
    localStorage.removeItem('currentPage');
    localStorage.removeItem('currentTime');
}

var updateTimings = function updateDict() {
    var pageName = localStorage.getItem('currentPage');
    if (!_.isEqual(pageName, null)) {
        var pages = JSON.parse(localStorage.getItem('userbehaviour'));
        if(pages !=null){
        pages[pageName] = _.parseInt(pages[pageName]) + _.floor((new Date().getTime() - _.parseInt(localStorage.getItem('currentTime'))) / 1000);
        localStorage.setItem('userbehaviour', JSON.stringify(pages))
        resetTime();
        }
    }
}

var postParams = function fetchParams(){
    console.log(JSON.parse(localStorage.getItem('userbehaviour')));
    return Object.assign({session_id: localStorage.getItem('sessionId')}, JSON.parse(localStorage.getItem('userbehaviour')));
}

export { setTimer, resetTime, updateTimings, postParams }