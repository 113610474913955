import React from 'react'
import _ from 'lodash'
import { Grid, Segment } from 'semantic-ui-react';
import MediaCard from './MediaCard'
import ErrorMessage from '../Common/ErrorMessage';
import { isMobile } from 'react-device-detect';

class CreateMediaGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    createMediaGrid = () => {
        const imagesPerRow = 5;
        var lengthOfMedia = this.props.mediaData.mediaUrls.length;
        return (
            <Grid stackable style={{ marginLeft: '4%' }} >
                {
                    _.map(_.range(0, Math.ceil(this.props.mediaData.mediaUrls.length / imagesPerRow)), (value) => {
                        return (<Grid.Row columns={imagesPerRow}stretched style = {{padding: '0em'}}>
                            {_.map(_.range(value * imagesPerRow, (value * imagesPerRow) + imagesPerRow), (columnIndex) => {
                                let currentMediaDetail = this.props.mediaData.mediaUrls[columnIndex];
                                if (columnIndex <= lengthOfMedia - 1)
                                    return (<Grid.Column stretched width={3} style = {{padding: '0em'}} verticalAlign={'middle'}><MediaCard  selectAllMedia = {this.props.selectAllMedia} selectMedia = {this.props.selectMedia} mediaDetail={currentMediaDetail} sourceName={_.isUndefined(this.props.mediaData['sourceIdToName'])?undefined: this.props.mediaData['sourceIdToName'][currentMediaDetail.sourceId]} /> </Grid.Column>)
                            })}
                        </Grid.Row>)
                    })
                }
            </Grid>
        )
    }

    render() {
        // console.log(this.props.showErrorMessage);
        // console.log(this.props.filterChosen);
        if (_.isEmpty(this.props.mediaData)) return <div   style = {{visibility: (this.props.showErrorMessage)?'visible': 'hidden', margin: '6rem', marginLeft: '0rem', paddingBottom: '25px'}}>
        <ErrorMessage/>
        </div>
        return (
            <Segment style={{ marginLeft: (isMobile)? "1rem": "0rem", marginTop: '5%', border: 'none', backgroundColor: '#1E1A19'}} >
                {this.createMediaGrid()}
            </Segment>
        )
    }
}

export default CreateMediaGrid;