import React from 'react'
import axios from 'axios'
import { BlueMoon, RequestToken } from '../BlueMoon';
import _ from 'lodash'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {isMobile} from 'react-device-detect'

class TopMedias extends React.Component{
    constructor(props){
        super(props);
        console.log(this.props.brand)
        this.state = {brand: this.props.brand, mediaUrls: []};
    }

    fetchTopMedias = () => {
        if(!_.isUndefined(this.state.brand))
        axios.get(`${BlueMoon.ip}topMedias/`, {
            params: {
                brand: this.state.brand
            }
        }).then((response)=>{
            this.setState({
                mediaUrls: response.data
            }, () => {
                console.log(this.state.mediaUrls)
            })
        }).catch((err)=>{
            RequestToken();
        });
    }

    componentWillReceiveProps = (nextProps, currentProps) => {
       console.log(nextProps.brand, this.state.brand)
        if(!_.isEqual(nextProps.brand, this.state.brand)){
            this.setState({brand: nextProps.brand, mediaUrls: []}, () => {
                this.fetchTopMedias();
            })
        }

    }

    componentDidMount = () => {
        this.fetchTopMedias();
    }   

    componentDidUpdate = () => {
        var carosel = document.getElementsByClassName("carousel carousel-slider")
        if(carosel.length > 0)
        {carosel[0].style.marginTop = '3rem'
        carosel[0].style.marginLeft = (isMobile)? '0rem': '1.5rem'
    }

    }

    render(){
        
        if(_.isEmpty(this.state.mediaUrls)) return null;
        
        return <Carousel  style = {{marginTop: '3rem'}} infiniteLoop = {true} autoPlay = {true} showThumbs = {false} width = {(isMobile)?'350px': _.round(window.innerHeight/2)}>
        {
            this.state.mediaUrls.map((url) => {
                return <div><img src = {url} /></div>
            })
        }
    </Carousel>
        
    }
}

export default TopMedias;