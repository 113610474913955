import React from 'react'
import axios from 'axios'
import _ from 'lodash'
import 'react-picky/dist/picky.css';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';
import CreateMediaGrid from '../NewsFeed/CreateMediaGrid'
import { Grid, Dimmer, Loader } from 'semantic-ui-react'
import $ from 'jquery'
import { BlueMoon, RequestToken } from '../BlueMoon';
import {isMobile} from 'react-device-detect'

class DisplayCollection extends React.Component {
    constructor(props) {
        super(props);
        this.state = { collectionsSelected: [], collections: [], start: 0, end: 12, mediaData: [], stopScrolling: false, updated:false };
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll);
        axios.get(`${BlueMoon.ip}QCData/unique/collections`).then((response) => {
            this.setState({
                collections: response.data, updated: true
            });
        }).catch((err)=>{
            RequestToken();
        }).catch((err)=>{
            RequestToken();
        });
    }

    onSelectFilter = (currentFilter, value) => {
        this.setState({
            collectionsSelected: value, mediaData: [], start: 0, end: 12
        }, () => {
            this.fetchMedias()
        });
    }

    handleScroll = e => {
        var _ = this;
        var height = $(document).height;
        var isActive = false;

        $(function () {

            $(window).on("scroll", function () {
                if($(window).scrollTop() + $(window).height() > $(document).height() - 100) 
                    {
                   
                if (!_.state.stopScrolling) {
                    _.setState({ stopScrolling: true, start: _.state.start + 1 }, () => _.fetchMedias())
                }
            }
            });
        });
    }

    formatData = (data) => {
        return data.reduce((prev, curr) => {
            if (_.isEmpty(prev))
                prev['mediaUrls'] = [curr['mediaUrl']];
            else
                prev['mediaUrls'].push(curr['mediaUrl']);
            return prev;
        }, {}).mediaUrls
    }


    fetchMedias = () => {
        
        if(!_.isEmpty(this.state.collectionsSelected))
        axios.get(`${BlueMoon.ip}QCData/filter/?collections=${this.state.collectionsSelected.join("<<!!>>")}&start=${this.state.start* 12}&end=${this.state.start*12 + 12}`).then((response) => {
           

            if (!_.isEmpty(response.data)) {
                if (!_.isEmpty(this.state.mediaData)) {
                    let tempMediaData = this.state.mediaData;
                    let tempMediaUrls = this.state.mediaData.mediaUrls;
                    tempMediaData['mediaUrls'] = tempMediaUrls.concat(response.data)
                    this.setState({
                        mediaData: tempMediaData
                    }, () => {
                        this.setState({ stopScrolling: false })
                    })
                } else {
                    this.setState({

                        mediaData: {
                            'mediaUrls': response.data,
                        }
                    }); } }
        this.setState({stopScrolling: false});
    }).catch((err)=>{
        RequestToken();
    })
    }



    render() {
        if (_.isEmpty(this.state.collections) && !this.state.updated) return <Dimmer active>
            <Loader/>
        </Dimmer>;
        return (
            <Grid>
                <Grid.Row style={{ marginTop: '5rem' }}>
                    <Grid.Column width={4} style={{ marginLeft: (isMobile)?"4rem":'5%' }}>
                        <Picky style={{ cursor: 'pointer', width: (isMobile)?"10rem":'100%' }} options={this.state.collections} value={this.state.collectionsSelected} placeholder={"Collections"} multiple={true} includeFilter={true} onChange={values => this.onSelectFilter("collections", values)} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16} style = {{marginLeft: (isMobile)?"1rem": ""}}>
                        {!_.isEmpty(this.state.mediaData) ? <CreateMediaGrid mediaData={this.state.mediaData} /> : null}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default DisplayCollection;