import React from 'react'
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';
import axios from 'axios'
import _ from 'lodash'
import { numericConvertor } from '../Common/NumericConvertor'
import { Grid, Loader, Dimmer, Header } from 'semantic-ui-react';
import { BlueMoon, RequestToken } from '../BlueMoon';
import HeatMap from 'react-heatmap-grid'
import {generateRandomColor} from '../Common/RandomColors'
import {isMobile} from 'react-device-detect'

class ComparisonGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = { comparisonData: [], dimmer: true,  colors: ['orange', 'purple', 'yellow', 'green', 'skyblue', 'brown', 'grey', 'olive'] };
    }

    reshapeData = (data) => {
        return data.reduce((prev, current) => {
            Object.keys(current).reduce((pr, cur) => {
                if (cur == "groupName")
                    return pr;
                if (!(cur in prev))
                    prev[cur] = [];
                prev[cur].push({ groupName: current.groupName, [cur]: current[cur] });
            }, {});
            return prev;
        }, {})

    }

    componentDidMount = () => {
        // console.log(this.props.selectedGroups);
        var counter = 0;
        axios.get(`${BlueMoon.ip}QCGroup/comparegroup?groupCreator=${'temp'}&groupName=${this.props.selectedGroups.join("<<!!>>")}`).then((response) => {
            this.setState({ comparisonData: this.reshapeData(response.data), dimmer: false }, () => {
                this.setState({collectionToColor: this.state.comparisonData['likes'].map((val)=>val.groupName).reduce((prev, curr) => {
                    if(counter > 6)
                    prev[curr] =  generateRandomColor()  
                    else
                    prev[curr] = this.state.colors[counter];
                    counter = counter + 1;
                    return prev; 
                }, {})  })
            }, () => {
                console.log(this.state.collectionToColor)
            })
        }).catch((err)=>{
            RequestToken();
        });
    }


    generateHeatMap = () => {
        console.log(this.state.collectionToColor)
        var collectionNames = this.state.comparisonData['duration'].map((doc) => {
            return doc.groupName;
        });

        var hours = collectionNames;
        var days = ['Duration'];
        // console.log(this.state.comparisonData);
        var totalDuration = this.state.comparisonData['duration'].reduce((prev, curr)=>{
            
            return curr['duration'] + prev;
        }, 0);
        var data = this.state.comparisonData['duration'].map((doc, index) => {
            return [0, index, _.round(_.round(doc['duration']/totalDuration, 2)*100, 2) ]
        })
        
        console.log(data);
        data = data.map(function (item) {
            return [item[1], item[0], item[2] || '-'];
        });
        var option = {}
        option = {
            backgroundColor: '#161A1C',
            title: {

                textStyle: {
                    fontSize: (isMobile)?"10": "20",
    
                },
                left: "20",
                text: (isMobile)?"":"Duration (s)"
            },
            tooltip: {
                position: 'top'
            },
            animation: false,
            grid: {
                top: '30%',
                height: '7%',
                left: '15%'
            },
            xAxis: {
                data: hours,
                 axisLabel: {
                    formatter: function (d) {
                        return d.substring(0, 13);
                    }
                }
            },
            yAxis: {
                type: 'category',
                data: days,
                splitArea: {
                    show: true
                }
            },
            visualMap: {
                min: 0,
                max: 100,
                calculable: true,
                orient: 'horizontal',
                left: 'center',
                bottom: '15%',
                show: false
            },
            series: [{
                name: 'Punch Card',
                type: 'heatmap',
                data: data,
                label: {
                    color: 'black',
                    show: true
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }]
        }; 
        return option;
    }

    generateComparisonDoughnut = (header, data) => {
        // console.log("iam the doghnut")
        var option = {};
        // console.log(header, data)
        var data = _.map(data, (val, index) => {
            
            return {
                value: val[header], name: this.props.selectedGroups[index], itemStyle: {color: this.state.collectionToColor[val.groupName], fontColor: 'black'}
            }
        });
        option = {
            backgroundColor: '#161A1C',
            title: {
                textStyle: {
                    fontSize: (isMobile)?"10": "20",
    
                },
                fontSize: (isMobile)?"10": "20",
                text: (isMobile)?"":"Value(ME Score)",
                left: '45%'
            },
            tooltip: {
                trigger: 'item',
                formatter: `{b}: {c} ({d}%)`
            },
            legend: {
                orient: 'vertical',
                left: 10,
                data: this.props.selectedGroups
            },
            series: [
                {
                    name: '访问来源',
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            show: false,
                            textStyle: {
                                fontSize: '30',
                                fontWeight: 'bold'
                            }
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data: data
                }
            ]
        };
        return option;

    }


    componentDidUpdate = () => {
        var elements = document.getElementsByClassName("ui selection simple dropdown");
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.backgroundColor = 'black'
            elements[i].style.color = 'white'
            elements[i].style.float = 'right'
            elements[i].style.marginRight = '1rem'
        }
    }


    rightSideBarGraph = () => {
        var collectionNames = this.state.comparisonData['docCount'].map((doc) => {
            return doc.groupName;
        });

        var collectiontoData = this.state.comparisonData['docCount'].reduce((prev, curr) => {
            prev[curr['groupName']] = [curr['docCount']]
            return prev;
        }, {});

        var seriesData =   _.map(_.keys(collectiontoData), (collection) => {
            return {
                name: collection,
                type: 'bar',
                label: seriesLabel,
                data: collectiontoData[collection],
                itemStyle: {color: this.state.collectionToColor[collection]}
            }
        });
    
        var seriesLabel = {
            normal: {
                show: true,
                textBorderColor: '#333',
                textBorderWidth: 2
            }
        }
        var option = {};
        option = {
            backgroundColor: '#161A1C',
           title: {
            textStyle: {
                fontSize: (isMobile)?"10": "20",

            },
            top: (isMobile)?0:20,
            text: (isMobile)?"":"Impressions (count)",
               textAlign: 'left',
               left: 'center'
               
           },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                data: collectionNames 
            },
            grid: {
                left: 100
            },
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {

                        title: "download image"
                        
                    }
                }
            },
            xAxis: {
                type: 'value',
                splitLine: {
                    show: false
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            yAxis: {
                type: 'category',
                inverse: true,
                data: ['MediaCount'],
                
                axisLabel: {
                    formatter: function (value) {
                        return value;
                    },
                   
                },
                
            },
            series: seriesData
        };
        
        return option;
    }

    stackedGraph = () => {
        var data = this.state.comparisonData;
        const xAxisData = ['Likes', 'Comments']
        
        var collectionToLikes = data['likes'].reduce((prev, curr)=>{
            prev[curr['groupName']] = [curr['likes']];
            return prev;
        }, {});
    
        var collectionToData = data['comments'].reduce((prev, curr)=>{
            let temp = prev[curr['groupName']];
            temp.push(curr['comments'])
            prev[curr['groupName']] = temp;
            return prev;
        }, collectionToLikes);

        var option = {};
        option = {
            animationDurationUpdate: function (idx) {
                // delay for later data is larger
                return 3000;
            },
            animationEasing: true,
            backgroundColor: '#161A1C',
           
            title: {
                text:(isMobile)?"": "Engagement Metrics",
                textStyle: {

                    fontSize: (isMobile)?"10": "20",
                    fontFamily: 'sans-serif',
                    fontWeight: 'bold',
                    interval: 4
                }, 
                top: '20', 
                left: 'center'
            },
            legend: {
                formatter: function (name) {
                return   name;
            }},
            tooltip: {
                
                textStyle: {
                    fontFamily: 'sans-serif',
                    fontSize: 13
                },
                trigger: 'axis',
                formatter: function (params) {
                    return `${params[0].axisValue}<br/><br/>` + params.map((data) => {
                        return `${data.data.collectionName}:&nbsp$${numericConvertor(data.value)}`
                    }).join("<br/>");
                },
                axisPointer: {
                    type: 'shadow',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            
            toolbox: {
                feature: {
                    saveAsImage: {
                        title: "download image"
                    }
                }
            },
            
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                height: '250px',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: true,
                    data: xAxisData,
                    axisLabel: {
                        fontFamily: 'sans-serif',
                        fontWeight: 'bold',
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine: {
                        show: false
                    }
                }
            ],
            series: _.keys(collectionToData).map((collection) => {
                return {
                    type: 'bar',
                    stack: 'stack',
                    name: collection,
                    thickness: '11',
                    itemStyle: {color: this.state.collectionToColor[collection]},
                    data: collectionToData[collection].map((freq) => {
                        return { value: freq, collectionName: collection
                    }
                    }),
                }
            })
        };
        return option;
    }




    chartGrid = () => {
       return( <Grid columns = {2} style = {{height: '50%'}} stackable>
           <Grid.Column>
           <ReactEcharts theme={'dark'} option={this.stackedGraph()} />
               </Grid.Column>
             <Grid.Column>
           <ReactEcharts theme={'dark'} option={this.rightSideBarGraph()} />
               </Grid.Column>
               <Grid.Column>
               <ReactEcharts theme={'dark'} option={this.generateComparisonDoughnut("meScore", this.state.comparisonData['meScore'])} />
               </Grid.Column>
               <Grid.Column>
               <ReactEcharts theme={'dark'} option={this.generateHeatMap()} />
               </Grid.Column>
            </Grid>);
    }



    
    render() {
        const options = [
            { key: 1, text: 'Doughnut', value: 'Doughnut' },
            { key: 2, text: 'Bar', value: 'Bar' },
        ]
       
        if (_.isEmpty(this.state.comparisonData) || _.isEmpty(this.state.collectionToColor)) return <Dimmer active>
            <Loader />
        </Dimmer>;
        return (
          this.chartGrid()
        );
    }
}

export default ComparisonGraph;