var reactDevice = require('react-device-detect')

 function detectDevice(){
     switch(true){
     case reactDevice.isMobile: return "mobile";
     case reactDevice.isTablet: return "tablet";
     case reactDevice.isSmartTV: return "smarttv";
     case reactDevice.isBrowser: return "browser";
     default: return "browser"
    }
 }

function browserVersion(){

    switch(true){
        case reactDevice.isChrome: return "google-chrome";
        case reactDevice.isOpera: return "opera";
        case reactDevice.isChromium: return "chromium";
        case reactDevice.isFirefox: return "firefox";
        case reactDevice.isSafari: return "safari"
    }
    
}

var deviceDetails = {deviceName: detectDevice(), browserVersion: browserVersion() + reactDevice.browserVersion, osName: reactDevice.osName};

export {deviceDetails};

