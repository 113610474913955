import React from 'react'
import { Grid, Icon, Label } from 'semantic-ui-react'
import _ from 'lodash'
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';
import AWSDatePicker from '../DatePicker/AWSDatePicker'
import { numberWithCommas } from '../Common/NumberWithComas'
import { shouldTypeVisible } from '../Common/CommanFunc'
import { isMobile } from 'react-device-detect';

class FilterEventDashBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = { filters: this.props.filters, isMediaExists: this.props.isMediaExists }

    }

    onSelectFilter = (filterName, value) => {
        console.log(value)
        this.props.filterSelect(filterName, value)
    }


    componentWillReceiveProps = (nextProps, currentProps) => {
        if (!_.isEqual(nextProps.filters, this.state.filters) || !_.isEqual(nextProps.isMediaExists, this.state.isMediaExists)) {
            console.log(nextProps.isMediaExists + " is media exists")
            this.setState({ filters: nextProps.filters, isMediaExists: nextProps.isMediaExists });
            return true;
        } return false;
    }



    createGrid = () => {

        if (!_.isEmpty(this.state.filters)) {
            console.log(this.state.filters)
            return (
                <div style={{ marginLeft: (isMobile)?"3rem":'5%', marginRight: '5%', marginTop: '0.5rem', backgroundColor: 'black' }}>
                    <Grid stackable >
                        {
                            <Grid.Row columns={_.keys(this.state.filters).length + 4}>

                                {_.map(_.keys(this.state.filters), (currentFilter) => {
                                    var visible = true;
                                    if (currentFilter === "channelType") {
                                        if (!_.isUndefined(this.props.selectedFilter)) {
                                            visible = shouldTypeVisible(this.props.selectedFilter['channel'])
                                        }
                                    }
                                    return (
                                        <Grid.Column width={4} verticalAlign={'middle'}>
                                            <Grid style={{ marginTop: '1rem'}}>
                                                <Grid.Row>
                                                    <Grid.Column textAlign={'right'} verticalAlign={'middle'} textAlign={'center'}>
                                                        <Icon name={'filter'} color={'teal'} />
                                                    </Grid.Column>
                                                    <Grid.Column floated={'left'} width={'12'} textAlign={'center'}>
                                                        <Picky style={{ cursor: 'pointer' }} options={(!visible) ? [] : this.state.filters[currentFilter]} placeholder={(currentFilter === "users") ? "Source" : (currentFilter === "channelType") ? "Channel Type" : _.capitalize(currentFilter)} value={_.isUndefined(this.props.selectedFilter[currentFilter]) ? [] : this.props.selectedFilter[currentFilter]} multiple={true} includeFilter={true} onChange={values => this.onSelectFilter(currentFilter, values)} />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column>
                                    )
                                }

                                )
                                }
                                <Grid.Column style={{ marginTop: '1.6rem', width: '3.25% !important' }} width={1} verticalAlign={'middle'}>
                                    <Icon name={'refresh'} style={{ cursor: 'pointer' }} color={'teal'} width={1} size={'large'} onClick={() => {
                                        this.props.reset();
                                    }} />
                                </Grid.Column>
                                <Grid.Column style={{ marginTop: '2.2rem' }} textAlign={'left'} width={1}>
                                    <Icon name={'add square'} disabled={!this.state.isMediaExists} color={'teal'} size={'large'} style={{ cursor: 'pointer' }} onClick={() => {
                                        this.props.addCollection();
                                    }} />
                                </Grid.Column>
                                <Grid.Column style={{ marginTop: '2.2rem' }} textAlign={'left'} width={1}>
                                    <Icon name={'selected radio'} disabled={!this.state.isMediaExists} color={'teal'} size={'large'} style={{ cursor: 'pointer' }} onClick={() => {
                                        this.props.allMedia();
                                    }} />
                                </Grid.Column>
                                <Grid.Column style={{ marginTop: '1.6rem' }} textAlign={'left'} width={1}>
                                    <div style={{ marginTop: '0.3rem' }}>
                                        <AWSDatePicker eventFilter={true} selectFilter={this.onSelectFilter} disabled={!this.state.isMediaExists} />
                                    </div>
                                </Grid.Column>

                                <Grid.Column textAlign={'center'} style={{ marginTop: '2rem' }}  width={1} disabled={!this.state.isMediaExists}>
                                    <Label color={'violet'}>${numberWithCommas(_.round(this.props.value))}</Label>
                                </Grid.Column>
                            </Grid.Row>
                        }</Grid>
                </div>
            )
        }
    }

    render() {
        console.log(this.state.filters, this.props.selectedFilter);
        return this.createGrid()
    }
}

export default FilterEventDashBoard;