import React from 'react'
import 'react-dropdown-tree-select/dist/styles.css'
import _ from 'lodash'
import axios from 'axios'
import { Grid, Segment } from 'semantic-ui-react'
import CollectionMetaDriller from './CollectionMetaDriller'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';
import { BlueMoon, Dimple } from '../BlueMoon'

class EventCollector extends React.Component {
    constructor(props) {
        super(props);
        let dashProps = this.props.location;
        this.state = { rawData: [], data: [], selectedFilters: {}, filterQuery: '', selectedMedia: _.isUndefined(dashProps.state) ? [] : dashProps.state.selectedMedia, addedFilters: _.isUndefined(dashProps.state) ? {} : dashProps.state.filters, allMediaSelected: _.isUndefined(dashProps.state) ? '' : dashProps.state.selectAllMedia };
    }

    notificationService = (title, message, type) => {
        store.addNotification({
            title: title,
            message: message,
            type: type,
            insert: "top",
            container: "top-right",
            width: 300,
            dismiss: {
                duration: 2000,
                pauseOnHover: true
            }
        });
    }


    mapItRecursiveFormat = (data, filteredNodes) => {
        return _.keys(data).map((key) => {
            return {
                label: key, value: key, children: data[key].map((val) => {
                    if (key in filteredNodes)
                        if (filteredNodes[key].includes(val))
                            return { label: val, value: val, checked: true };
                    return { label: val, value: val }
                })
            }
        })

    }

    fetchFilters = () => {
        axios.get(`${BlueMoon.ip}QCData/filterdata${_.isUndefined(this.state.fil) ? '' : `q${this.state.fil}`}`).then((response) => {
            var temp = response.data;
            this.setState({ data: this.mapItRecursiveFormat(response.data, this.state.addedFilters), rawData: response.data }, () => {
                if (!_.isEmpty(this.state.addedFilters)) {
                    this.setState({ filterQuery: this.filterQ(this.state.addedFilters) })
                }
            });
        })
    }

    filterQ = (filteredNodes) => {
        if (!_.isEmpty(_.keys(this.state.selectedMedia)))
            return Object.assign(filteredNodes, { id: _.keys(this.state.selectedMedia), selectedAll: [this.state.allMediaSelected] });
        return Object.assign(filteredNodes, { selectedAll: [this.state.allMediaSelected] });
    }


    componentDidMount = () => {
        this.fetchFilters();
    }

    componentDidUpdate = () => {
        document.getElementsByClassName("search")[0].placeholder = 'Filter';
        document.getElementsByClassName("search")[0].style.fontSize = 'large';
        document.getElementsByClassName("search")[0].style.fontFamily = 'sans-serif';

    }


    onChange = (currentNode, selectedNodes) => {
        var filteredNodes = selectedNodes.reduce((prev, curr) => {
            var parentNode = this.state.data[_.toInteger(_.split(curr._id, '-')[1])].label;
            if (parentNode != currentNode.value) {
                if (!(parentNode in prev))
                    prev[parentNode] = [];
                prev[parentNode].push(curr.value);
            }
            return prev;
        }, {});
        this.setState({
            selectedFilters: filteredNodes, data: this.mapItRecursiveFormat(this.state.rawData, filteredNodes), filterQuery: this.filterQ(filteredNodes)
        });
    }


    shouldComponentUpdate = (prevProps, prevState) => {
        console.log(prevState.filterQuery);
        console.log(this.state.filterQuery);
        return !_.isEqual(prevState.filterQuery, this.state.filterQuery) | _.isEmpty(this.state.filterQuery);
    }

    onAction = (node, action) => {
        // console.log('onAction::', action, node);
    }

    onNodeToggle = currentNode => {
        // console.log('onNodeToggle::', currentNode);
    }


    filters = () => {
        return (
            <Grid columns={4} divided >
                <Grid.Row>
                    {/* <Grid.Column width={1} verticalAlign={'middle'}><Icon color={'grey'} name={'add square'} size={'large'} /></Grid.Column> */}
                    {/* <Grid.Column width={14} verticalAlign={'middle'}> <div style={{ marginTop: '0.3rem' }}><DropdownTreeSelect data={this.state.data} onChange={this.onChange} /></div></Grid.Column> */}
                </Grid.Row>
            </Grid>
        );
    }



    render() {
        var data = this.state.data
        console.log(data);
        if(_.isEmpty(localStorage.getItem('user'))) this.props.history.push('./login')
        if (_.isEmpty(localStorage.getItem('projectIp'))) window.location.replace(`${Dimple}#/${localStorage.getItem('companyLogo')}`)

        if (!_.isEmpty(this.state.data))
            return (
                <Segment inverted size={'small'}>
                    <ReactNotification />

                    <div style={{ marginLeft: '3rem', marginTop: '1rem', marginRight: '2rem'}}>
                        {this.filters()}
                        {/* {console.log(this.state.filterQuery)} */}
                        {(this.state.filterQuery != '') ? <CollectionMetaDriller filters={this.state.filterQuery} notificationService={this.notificationService} /> : null}
                    </div>

                </Segment>
            )
        return null;
    }

}

export default EventCollector;