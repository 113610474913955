import React from "react"
import ReactEcharts from 'echarts-for-react';
import {Label} from 'semantic-ui-react'
import axios from 'axios'
import _ from 'lodash'
import {BlueMoon} from '../BlueMoon'

class Speedometer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expectedPercent: null, params: this.props.params
        }
    }

    getSpeedometerOption = (speedometerValue) => {
        var option = {
            backgroundColor: '#161A1C',
            tooltip : {
            },
            
            toolbox: {
                show : true,
                feature : {
                    
                }
            },
            
            series : [
                {
                    name:'Reach%',
                    type:'gauge',
                    data: [{value: 0}, {value: 0}],
                    min:0,
                    max:100,
                    animation: true,
                    animationDurationUpdate: 10000,
                    splitNumber: 10,
                    radius: '90%',
                    axisLine: {           
                        lineStyle: {       
                            color: [[0.09, 'red'], [0.25, 'red'],[0.45, '#ff4500'],[0.7, '#0F9FE7'], [1, '#12E438']],
                            width: 15,
                            shadowColor : '#fff', 
                            shadowBlur: 10
                        }
                    },
                    axisLabel: {            
                        textStyle: {       
                            fontWeight: 'bolder',
                            color: '#fff',
                            shadowColor : '#fff', 
                            shadowBlur: 10
                        }
                    },
                    axisTick: {            
                        length :15,        
                        lineStyle: {       
                            color: 'auto',
                            shadowColor : '#fff', 
                            shadowBlur: 10
                        }
                    },
                    splitLine: {           
                        length :25,         
                        lineStyle: {       
                            width:3,
                            color: '#fff',
                            shadowColor : '#fff', 
                            shadowBlur: 10
                        }
                    },
                    pointer: {           
                        shadowColor : '#fff', 
                        shadowBlur: 5,
                    },
                    title : {
                       
                        textStyle: {
                            fontWeight: 'bolder',
                            fontSize: 20,
                            fontStyle: 'italic',
                            color: '#fff',
                            shadowColor : '#fff', 
                            shadowBlur: 10
                        }
                    },
                    detail : {
                        show: false,
                        backgroundColor: '#333',
                        borderWidth: 1,
                        borderColor: '#333',
                        shadowColor : '#333', 
                        shadowBlur: 5,
                        textStyle: {      
                            fontWeight: 'bolder',
                            color: '#333'
                        }
                    },
                    
                    data:[{value: _.round(speedometerValue)}],  
                    

                }
                
            ]
        };
        return option;
    }

    fetchEvents = () => {
        axios.get(`${BlueMoon.ip}dashboard/speedometer`, {
            params: this.state.params
        }).then((response)=>{
           
           this.setState({ expectedPercent: response.data});
        })
    }

    componentWillReceiveProps = (nextProps, currentProps) => {
        // console.log("component will recieve in the speedometer is called");
        if (!_.isEqual(nextProps.params, this.state.params)) {
        this.setState({params: nextProps.params, expectedPercent: null}, () => {
            this.fetchEvents();
        })
        return true;
    }
    return true;
    }
    componentDidMount = () => {
        this.fetchEvents();
    }

    render() {
        if(_.isNull(this.state.expectedPercent)) return null;
        // console.log(this.state.expectedPercent)
        return (
            <div style = {{  borderRadius: '10px', backgroundColor: "black", marginTop: '1rem'}}>
        <Label as='a' ribbon color={'blue'} style={{ marginLeft: '1rem'}} size={'large'}>{"Expected Reach"}</Label>

            <ReactEcharts
            
                option={this.getSpeedometerOption(this.state.expectedPercent*100)}
                notMerge={true}
                style={{height: '240px', width: '100%'}}
                lazyUpdate={false}
                theme={"dark"} />
                </div>
        )
    }

}

export default Speedometer;