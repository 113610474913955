import React from 'react'
import axios from 'axios'
import _ from 'lodash'
import ReactEcharts from 'echarts-for-react';
import { Grid, Table, Dimmer, Loader, Icon } from 'semantic-ui-react'
import InsightsFeedBack from './InsightsFeedBack'
import {BlueMoon, RequestToken} from '../BlueMoon'
import {numberWithCommas} from '../Common/NumberWithComas'
import './legend.css'
import { numericConvertor } from '../Common/NumericConvertor';
import { Dimple } from '../BlueMoon'

import * as html2canvas from 'html2canvas';

class DataInsights extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            insightsData: [], rerender: false, brandToColor: {}, dimmer: true
        };
    }

    getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }


    sumItUp = (data, column) => {
        return data.reduce((prev, curr) => {
            return prev + curr[column];
        }, 0);
    }

    getRandomRgb = () => {
        var r = this.getRandomInt(0, 255);          // Random between 0-255
        var g = this.getRandomInt(0, 255);          // Random between 0-255
        var b = this.getRandomInt(0, 255);          // Random between 0-255
        return 'rgb(' + r + ',' + g + ',' + b + ')'
    }

    mapDataToField = (data, value) => {

        return ((data).map((x) => {
            return x[value]
        }))
    }
    getBarChart = () => {

        var data = _.sortBy(_.keys(this.state.insightsData).map((val) => {
            return Object.assign(this.state.insightsData[val], { brandName: val });
        }), function (i) {
            return i.duration
        });

        
        var xAxisData = this.mapDataToField(data, "duration");

        var barData = data.map((value) => {
            return { value: value['value'], itemStyle: { color: this.state.brandToColor[value['brandName']], symbolSize: "10", brandName: value['brandName'] } };

        });

        var option = {
            backgroundColor: '#161A1C',
            title:{
                text: 'Duration vs Value'
            },
            xAxis: {
                name: 'Duration'
            },
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    if (!_.isUndefined(params[0].data))

                        return `Brand: ${_.capitalize(params[0].data.itemStyle.brandName)} <br/> ${_.capitalize("Duration")}: ${numberWithCommas(_.round(params[0].axisValue))}s <br/> ${_.capitalize("Value")}: $${numberWithCommas(_.round(params[0].data.value))}`
                },
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: xAxisData,
                    axisTick: {
                        alignWithLabel: true
                    }
                }
            ],
            yAxis: [
                {
                    name: "Value($)",
                    splitLine: {
                        show: false
                    },
                    type: 'value'
                }
            ],
            series: [
                {

                    type: 'bar',
                    data: barData
                }
            ]
        };
        return { option: option, topTen: _.slice(data, data.length - 10, data.length) };
    }


    pieChart = () => {
        var colors = ['purple', 'yellow', 'orange', 'green', 'olive']
        var assetNames = this.state.assetData.assetContrib.map((x) => {
            
            return x.assetName
        })
       var  option = {
           backgroundColor: '#161A1C',
            tooltip: {
                trigger: 'item',
                formatter: '{b}:({d}%)'
            },
            title: {
                text: 'Asset Share of Value'
            },
            legend: {
                top: '60%',
                orient: 'vertical',
                left: 10,
                data: assetNames
            },
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {

                        title: "download image"
                        
                    }
                }
            },
            series: [
                {
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        normal: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            show: false,
                            textStyle: {
                                fontSize: '30',
                                fontWeight: 'bold'
                            }
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data: this.state.assetData.assetContrib.map((val, index) => {
                        return {
                            value: val.percentile,
                            name: val.assetName,
                            itemStyle: {
                                color: colors[index]
                            }
                        }
                    })
                        
                    
                }
            ]
        };
        
return option;        
    }

    scatterPlot = (symbolType, title, xLabel, yLabel, mediaType) => {
        // console.log(this.state.assetData)
        
        var data = _.isEqual(symbolType, "circle")?this.state.assetData: this.state.insightsData
        // console.log(data);
        if (_.isEqual(symbolType, "square")) {
            var maxAssetLength = _.keys(this.state.insightsData).reduce((prev, curr) => {
                var assetLength = Array.from(this.state.insightsData[curr]['location']).length;
                if (prev < assetLength)
                    prev = assetLength;
                return prev;
            }, 0);
        }



var seriesData = (symbolType === "circle")? _.keys(data).map((value, index) => {
    var tempObj = data[value];
    return {
        type: 'scatter',
        data: [tempObj[xLabel], tempObj[yLabel]],
        name: value,
        symbolSize: _.isEqual(symbolType, "circle") ? 15: _.round(Array.from(tempObj['location']).length / maxAssetLength) * 16 + 7,
        itemStyle: { color: _.isEqual(symbolType, "circle")? this.getRandomRgb(): this.state.brandToColor[value], brandName: value },
        symbol: symbolType,

    }
}):[{
    data: _.keys(data).map((value, index) => {
        var tempObj = data[value];
        return {
            type: 'scatter',
            value: [tempObj[xLabel], tempObj[yLabel]],
            name: value,
            symbolSize: _.isEqual(symbolType, "circle") ? 15: _.round(Array.from(tempObj['location']).length / maxAssetLength) * 16 + 7,
            itemStyle: { color: _.isEqual(symbolType, "circle")? this.getRandomRgb(): this.state.brandToColor[value], brandName: value },
            symbol: symbolType,

        }
    }),


    type: 'scatter'
}]

        var option = {
            title: {
                text: title
            },
            backgroundColor: '#161A1C',
            
          
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    if (!_.isUndefined(params[0].data))
                        return `${(_.isEqual(symbolType, "circle")?"Asset": "Brand")}: ${_.isEqual(symbolType, "circle")?_.capitalize(params[0].data.name): _.capitalize(params[0].data.itemStyle.brandName)} <br/> ${_.capitalize(xLabel)}: ${numberWithCommas(_.round(params[0].data.value[0]))}s <br/> ${_.capitalize(yLabel)}: ${(yLabel === "value")?"$": ""}${numberWithCommas(_.round(params[0].data.value[1]))}${(yLabel === "value")?"": "%"}`
                },
                
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {

                        title: "download image"
                        
                    }
                }, right: '10'
            },
            legend: {
                data: _.keys(this.state.assetData).slice(1, 5)
                
            },
        
            
            xAxis: {
                splitLine: {
                    show: false
                },
                name: _.capitalize(xLabel),
                nameGap: 0,
            },
            yAxis: {
                splitLine: {
                    show: false
                },
                axisLabel: {
                    formatter: function (d) {
                        return numericConvertor(d)
                    }
                },
                name: (yLabel === "value")?"Value($)": "BrandSize"
            },
            series: seriesData
        };
        return option;
    }

    assignValueToObj = (value, duration, assetDocCount, docCount, assetPercent, brandSize, locationSet) => {
        return {
            value: value,
            duration: duration,
            assetDocCount: assetDocCount,
            docCount: docCount,
            assetPercent: assetPercent,
            brandSize: brandSize,
            location: locationSet
        }
    }

    durationVsMediavalue = (data) => {

        return (data.reduce((prev, curr) => {
            if ((curr['duration'] > 0)) {
                var brandName = curr['brand'];
                var value = curr['value'];
                var duration = curr['duration'];
                var location = curr['location'];
                var docCount = curr['docCount'];
                var brandSize = curr['brandSize'];
                var assetName = curr['location'];
                if (!(brandName in prev)) {
                    locationSet = new Set();
                    if (!_.isEqual(curr.brand, location))
                        locationSet.add(assetName);
                    prev[brandName] = this.assignValueToObj(value, duration, !_.isEqual(brandName, location) ? docCount : 0, docCount, _.isEqual(brandName, location) ? 0 : 1, brandSize, locationSet);
                }
                else {
                    var prevValue = prev[brandName];
                    var locationSet = prevValue['location'];
                    if (!_.isEqual(curr.brand, location))
                        locationSet.add(assetName);
                    prev[brandName] = this.assignValueToObj(prevValue['value'] + value, prevValue['duration'] + duration, (!_.isEqual(curr.brand, location) ? docCount : 0) + prevValue['docCount'], docCount + prevValue['docCount'], prev[brandName]['assetDocCount'] / prev[brandName]['docCount'], prevValue['brandSize'] + brandSize, locationSet);
                }
            }
            return prev;
        }, {}));

    }

    aggByAssets = (data) => {
     
       var j = data.reduce((prev, curr)=>{
           var location = curr['location'];

           if(!(location in prev)){
              prev[location] = {"duration": 0, "value": 0} 
            }
            prev[location]['duration'] = prev[location]['duration'] + curr['duration']
               prev[location]['value'] = prev[location]['value'] + curr['value']
            
               return prev;
       }, {});
       return j;

    }

    fetchAssets = () => {
        axios.get(`${BlueMoon.ip}dashboard/assetContrib/`).then((response) => {
            this.setState({
                assetData: response.data,  dimmer: false
            }, ()=>{
                console.log(this.state.assetData)
            })
    }).catch((err)=>{
        RequestToken();
    });
    }

    downloadURI = (uri, name) =>{
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        link.click();
        //after creating link you should delete dynamic link
        //clearDynamicLink(link); 
    }

    printToFile = () => {
        var div = document.getElementsByClassName("row")[1];
        window.scrollTo(0,0);     

        html2canvas(div).then((canvas) =>{
           
                var myImage = canvas.toDataURL("image/png");
                                //create your own dialog with warning before saving file
                //beforeDownloadReadMessage();
                //Then download file
                this.downloadURI("data:" + myImage, "BrandTrends.png");
                window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
            }
        )
    }

    componentDidMount = () => {
        axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`}

        axios.get(`${BlueMoon.ip}insights/`).then((response) => {
            this.setState({ insightsData: this.durationVsMediavalue(response.data) }, () => {
                this.setState({
                    brandToColor: _.keys(this.state.insightsData).reduce((prev, curr) => {
                        prev[curr] = this.getRandomRgb();
                        return prev;
                    }, {})
                })
                this.fetchAssets(); 
            })
                
    
    }).catch((err)=>{
        RequestToken();
    })
}

componentDidUpdate = () => {
  
}
    getLegend = (data) => {

        return (<Table  className = {'assetLegend'} style = {{backgroundColor: '#161A1C', color: 'white', fontFamily: 'sans-serif'}} stackable>
            <Table.Body>
                {_.map(_.range((data.length / 2)), (value, index) => {
                    // console.log(value);
                    return (
                        <Table.Row >
                            <Table.Cell>
                                <div style={{ backgroundColor: `${this.state.brandToColor[data[value * 2].brandName]}`, padding: '8px', width: '1px', height: '1px', fontFamily: 'sans-serif'}} />
                            </Table.Cell>
                            <Table.Cell style = {{fontFamily: 'sans-serif'}}>{_.capitalize(data[value * 2].brandName)}</Table.Cell>

                             
                                        <Table.Cell>
                                            <div style={{ backgroundColor: `${this.state.brandToColor[data[value * 2 + 1].brandName]}`, marginTop: '', padding: '8px', width: '1px', height: '1px', fontFamily: 'sans-serif'}} />
                                        </Table.Cell>
                                        <Table.Cell style = {{ fontFamily: 'sans-serif'}}>
                                            <span>{_.capitalize(data[value * 2 + 1].brandName)}</span>
                                        </Table.Cell>

                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>)
    }

    render() {
        if(_.isEmpty(localStorage.getItem('user'))) this.props.history.push('./login')

        if (_.isEmpty(localStorage.getItem('projectIp'))) window.location.replace(`${Dimple}#/${localStorage.getItem('companyLogo')}`);

        if (_.isEmpty(this.state.insightsData) || _.isEmpty(this.state.brandToColor) || _.isEmpty(this.state.assetData)) return (<Dimmer active={this.state.dimmer}>
        <Loader />
    </Dimmer>)

var barData = this.getBarChart();
        return (
            <Grid stackable style = {{marginTop: '3rem'}}>
                <InsightsFeedBack />
                <Grid.Row columns={2}>
                    <Grid.Column width={8}>
                        <div style={{marginLeft: (window.innerWidth>800)?'3rem': '2rem', marginTop: '4rem', width: '96%' }} >
                        <ReactEcharts
                                option={this.scatterPlot("square", "Duration vs. Brands Size", "duration", "brandSize", "media")}
                                notMerge={true}
                                lazyUpdate={true}
                                theme={"dark"} />

                        </div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <div style={{marginLeft: (window.innerWidth>800)?'0rem': '2rem', marginTop:'4rem', width: '100%' }} >
                        <ReactEcharts
                                option={this.pieChart()}
                                notMerge={true}
                                lazyUpdate={true}
                                theme={"dark"} />

                                
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style = {{backgroundColor: '#161A1C'}}>
                    <Grid.Column width={12}>
                        <div style={{ marginLeft: (window.innerWidth>800)?'4rem': '2rem', width: '100%' }} >

                            <ReactEcharts
                                option={barData.option}
                                notMerge={true}
                                lazyUpdate={true}
                                theme={"dark"} />
                        </div>
                    </Grid.Column>
                    
                    <Grid.Column width={3} textAlign = {'right'} style = {{left: '3%'}}>
                        {this.getLegend(barData.topTen)}
                    </Grid.Column>
                    <Grid.Column width = {1}>
                    <Icon name = {'download'} style = {{color: 'white', cursor: 'pointer', float: 'right'}} onClick = {this.printToFile} />

                        </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default DataInsights;