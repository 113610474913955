var mapChannelToColor = {
    "social": "#4444A5", 
    "highlights": "yellow",
    "digital": "#FF9027",
    "linear": "#44A1A5"
}

var channelToColor = function(channelName){
    return mapChannelToColor[channelName.toLowerCase()];
}

export {channelToColor}