import React from 'react'
import TableTemplate from './TableTemplate'
import axios from 'axios'
import {BlueMoon} from '../BlueMoon'
import _ from 'lodash'

class UserTracker extends React.Component{
    constructor(props){
        super(props);
        this.state = {userBehaviourData: [], colors: ["#161A1C", "#4C464F"]};
    }

    componentDidMount = () => {
        this.fetchBehaviour();
    }

    componentDidUpdate = () => {
        if (!_.isEmpty(document.getElementsByTagName('tr'))) {
            var thElements = document.getElementsByTagName('tr')[0].getElementsByTagName('th');
            for (var i = 0; i < thElements.length; i++)
                thElements[i].setAttribute('style', 'font-size: 1.1em;color: chartreuse !important;font-family: sans-serif;paddingLeft:0em;paddingRight:0em');
        }
    }

    fetchBehaviour = () => {
        axios.get(`${BlueMoon.ip}get/userpagetimings`).then((response) => {
            this.setState({
                userBehaviourData: response.data
            });
        });
    }
    render(){
        if(_.isEmpty(this.state.userBehaviourData)) return null;

       return <div style={{ fontFamily: 'sans-serif', backgroundColor: '#161A1C' }}>
        <TableTemplate data = {this.state.userBehaviourData}   serviceCaller = {"behaviour" } columns = {_.keys(this.state.userBehaviourData[0]).reverse()}/>
    </div>
    }
}

export default UserTracker;